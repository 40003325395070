import { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TablePagination,
  TableSortLabel,
  FormControlLabel,
  Box,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {
  accountSearch,
  apiSuccessSelector,
  loadingSelector,
  searchAccountsSelector,
  selectedSearchAccountSelector,
  setApiSuccess,
  setSearchAccounts,
  setSelectedSearchAccount,
} from '../accounts/accountSlice';
import { AccountCircle } from '@mui/icons-material';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { addVerifyCallerId, EnableAccountCallerId } from './staffSlice';
import TextMessageEditDialog from '../../components/accounts/textMessages/TextMessageEditDialog';
import CustomizedProgressLoader from '../shared/CustomizedProgressLoader';
import { setCustomizedProgressLoading } from '../../slices/customizedProgressLoaderSlice';
import { createTextMessagesDashboard } from '../accounts/textMessages/textMessageSlice';
import EditIcon from '@mui/icons-material/Edit';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';
import PortalTableRow from '../shared/components/PortalTableRow';
import { exportJsonDataToCSVFile } from '../../utils/fileUtil/fileSaver';
import { setPortalSnackInfo } from '../../app/authSlice';
import GetAppIcon from '@mui/icons-material/GetApp';

const PREFIX = 'AccountLookup';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  controlPaper: `${PREFIX}-controlPaper`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardHeader: `${PREFIX}-cardHeader`,
  noRecordsRow: `${PREFIX}-noRecordsRow`,
  tableHead: `${PREFIX}-tableHead`,
  selectedRow: `${PREFIX}-selectedRow`,
  noRecordsBox: `${PREFIX}-noRecordsBox`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.controlPaper}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.cardContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.cardHeader}`]: {
    margin: 0,
    padding: 10,
    background: '#e0e0e0',
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.noRecordsRow}`]: {
    textAlign: 'center',
  },

  [`& .${classes.tableHead}`]: {
    background: theme.palette.primary.light,
  },

  [`& .${classes.selectedRow}`]: {
    background: theme.palette.secondary.light,
  },

  [`& .${classes.noRecordsBox}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <PortalIconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="previous page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </PortalIconButton>
      <PortalIconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </PortalIconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function AccountLookup(props) {
  const dispatch = useDispatch();
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [tollFree, setTollFree] = useState('');
  const [affiliate, setAffiliate] = useState('');
  const [receiverID, setReceiverID] = useState('');
  const searchAccounts = useSelector(searchAccountsSelector);
  const [filteredSearchAccounts, setFilteredSearchAccounts] = useState([]);
  const selSearchAcctNum = useSelector(selectedSearchAccountSelector);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.mode !== 'search' && props.mode !== 'callmask' ? 1000 : 10,
  );
  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState('CMPNY_NAME_TXT');
  const [open, setOpen] = useState(false);
  const [editCallerIdOpen, setEditCallerIdOpen] = useState(false);
  const [editTextRequestOpen, setEditTextRequestOpen] = useState(false);
  const [chooseNumber, setChooseNumber] = useState('');
  const [choosedAccount, setChoosedAccount] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [messagingEnabled, setMessagingEnabled] = useState(false);
  const [callerIdhelperText, setCallerIdhelperText] = useState('Enter 10 digit format');
  const [callerIdError, setCallerIdError] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const loading = useSelector(loadingSelector);
  const apiSuccess = useSelector(apiSuccessSelector);
  const [displayCancelledAccounts, setDisplayCancelledAccounts] = useState(true);
  const { t } = useTranslation();

  /**
   * on checkbox change, we reset page value to render data from first pagination
   */
  useEffect(() => {
    setPage(0);
  }, [displayCancelledAccounts]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    performFilterOnSearchAccounts();
  }, [searchAccounts]);

  useEffect(() => {
    dispatch(setApiSuccess(false));
  }, []);

  useEffect(() => {
    dispatch(setCustomizedProgressLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (props?.showCancelledAccountDefaultValue === false) {
      setDisplayCancelledAccounts(false);
    } else {
      setDisplayCancelledAccounts(true);
    }
  }, [props?.showCancelledAccountDefaultValue]);

  useEffect(() => {
    performFilterOnSearchAccounts();
  }, [displayCancelledAccounts]);

  const performFilterOnSearchAccounts = () => {
    if (displayCancelledAccounts) {
      setFilteredSearchAccounts(searchAccounts);
    } else {
      setFilteredSearchAccounts(
        searchAccounts?.filter((item) => item['ACNT_STATUS_REF'] !== 'CNCL'),
      );
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    switch (event.target.name) {
      case 'chooseNumber':
        setChooseNumber(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        if (value != '') {
          setCallerIdError(false);
          setCallerIdhelperText('Enter 10 digit format');
        }
        break;
      case 'messagingEnabled':
        setMessagingEnabled(!messagingEnabled);
        break;
      default:
    }
  };

  const handleDisplayCancelledAccount = (event) => {
    setDisplayCancelledAccounts(event.target.checked);
  };

  const handleCloseEditDialog = () => {
    setShowEditDialog(false);
  };

  const disableTextRequest = () => {
    dispatch(setCustomizedProgressLoading(true));
    const postData = {
      accountId: choosedAccount.ACCOUNT_ID,
      phone: phoneNumber,
      verificationStatus: 'DISABLED',
    };
    dispatch(createTextMessagesDashboard(postData));
    setEditTextRequestOpen(false);
    dispatch(
      accountSearch(
        accountName,
        accountNumber.replaceAll('-', ''),
        tollFree.replaceAll('-', ''),
        affiliate.replaceAll('-', ''),
        receiverID.replaceAll('-', ''),
      ),
    );
    dispatch(setCustomizedProgressLoading(false));
  };

  const handleAddVerifiedCallerId = (account) => {
    dispatch(setCustomizedProgressLoading(true));
    setChoosedAccount(account);
    dispatch(EnableAccountCallerId(account.ACCOUNT_ID, 'PENDING'));

    dispatch(setSelectedSearchAccount(''));
    if (accountName && accountName.length < 3) {
      return;
    }
    dispatch(
      accountSearch(
        accountName,
        accountNumber.replaceAll('-', ''),
        tollFree.replaceAll('-', ''),
        affiliate.replaceAll('-', ''),
        receiverID.replaceAll('-', ''),
      ),
    );
    dispatch(setCustomizedProgressLoading(false));
  };

  const verifyCaller = async () => {
    dispatch(setCustomizedProgressLoading(true));
    if (phoneNumber != '') {
      await dispatch(
        addVerifyCallerId(phoneNumber, choosedAccount.ACCOUNT_ID, choosedAccount.tollFreeNumber),
      );
      const postData = {
        accountId: choosedAccount.ACCOUNT_ID,
        phone: phoneNumber,
        verificationStatus: 'PENDING',
      };
      await dispatch(createTextMessagesDashboard(postData));

      await dispatch(
        accountSearch(
          accountName,
          accountNumber.replaceAll('-', ''),
          tollFree.replaceAll('-', ''),
          affiliate.replaceAll('-', ''),
          receiverID.replaceAll('-', ''),
        ),
      );
      dispatch(setCustomizedProgressLoading(false));
      setOpen(false);
    } else {
      setCallerIdError(true);
      setCallerIdhelperText('Caller ID cannot be empty');
    }
  };

  const handleAddTextRequestMessaging = (account) => {
    setShowEditDialog(true);
    setChoosedAccount(account);
  };

  const disableVerifiedCallerId = (value) => {
    dispatch(EnableAccountCallerId(choosedAccount.ACCOUNT_ID, value));
    dispatch(setSelectedSearchAccount(''));
    if (accountName && accountName.length < 3) {
      return;
    }
    setEditCallerIdOpen(false);
    dispatch(
      accountSearch(
        accountName,
        accountNumber.replaceAll('-', ''),
        tollFree.replaceAll('-', ''),
        affiliate.replaceAll('-', ''),
        receiverID.replaceAll('-', ''),
      ),
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditCallerIdClose = () => {
    setEditCallerIdOpen(false);
  };

  const handleEditCallerIdOpen = (account) => {
    setChoosedAccount(account);
    setEditCallerIdOpen(true);
  };

  const handleTextRequestClose = () => {
    setEditTextRequestOpen(false);
  };

  const handleTextRequestOpen = (account) => {
    setChoosedAccount(account);
    setEditTextRequestOpen(true);
  };

  const resetSearch = () => {
    setAccountName('');
    setAccountNumber('');
    setTollFree('');
    setAffiliate('');
    setReceiverID('');
    dispatch(setSearchAccounts([]));
    dispatch(setApiSuccess(false));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    window.scrollTo(0, 0);
  };

  const handleTextChange = (event) => {
    switch (event.target.name) {
      case 'accountName':
        setAccountName(event.target.value);
        break;
      case 'accountNumber':
        setAccountNumber(event.target.value);
        break;
      case 'tollFree':
        setTollFree(event.target.value);
        break;
      case 'affiliate':
        setAffiliate(event.target.value);
        break;
      case 'receiverID':
        setReceiverID(event.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      accountName === '' &&
      accountNumber === '' &&
      tollFree === '' &&
      affiliate === '' &&
      receiverID === ''
    ) {
      dispatch(setSearchAccounts([]));
      dispatch(setSelectedSearchAccount(''));
    }
  }, [dispatch, accountName, accountNumber, tollFree, affiliate, receiverID]);

  const handleClick = (event) => {
    dispatch(setSelectedSearchAccount(''));
    if (accountName && accountName.length < 3) {
      return;
    }
    dispatch(
      accountSearch(
        accountName,
        accountNumber.replaceAll('-', ''),
        tollFree.replaceAll('-', ''),
        affiliate.replaceAll('-', ''),
        receiverID.replaceAll('-', ''),
      ),
    );
  };
  const handleRowClick = (event, selAccountNum) => {
    dispatch(setSelectedSearchAccount(selAccountNum));
  };

  const createSortHandler = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
    }
    setSortKey(key);
  };

  const getActivationDate = (row) => {
    return row.ACTIVATE_DATE
      ? moment(row.ACTIVATE_DATE, 'YYYYMMDDHHmmss').format('MM/DD/YYYY')
      : row.CONV_SRVC_START_DATE
        ? moment(row.CONV_SRVC_START_DATE, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
        : 'NA';
  };

  const formatContactForAccount = (row) => {
    const firstName = row.SRVC_CNTCT_FIRST_NAME_TXT || '';
    const lastName = row.SRVC_CNTCT_LAST_NAME_TXT || '';
    const address1 = row.SRVC_ADDRS1_TXT || '';
    const address2 = row.SRVC_ADDRS2_TXT || '';
    const city = row.SRVC_CITY_TXT || '';
    const state = row.SRVC_STATE_REF || '';
    const zip = row.SRVC_ZIP_NUM || '';
    const voicePhone = row.SRVC_VOICE_PHONE || '';
    const faxPhone = row.SRVC_FAX_PHONE || '';
    const email = row.SRVC_EMAIL_TXT || '';

    return `${firstName} ${lastName}\n${address1} ${address2}\n${city} ${state} ${zip}\nVoice: ${voicePhone}\nFax: ${faxPhone}\nEmail: ${email}`;
  };

  const handleDownloads = () => {
    try {
      const data = filteredSearchAccounts.map((account) => {
        return {
          Account: account?.ACNT_NUM || '',
          'Account Name': account?.CMPNY_NAME_TXT || '',
          'Brand/Acquisition': account?.ACQ_COMPANY || '',
          'Parent Line': account?.tollFreeNumber || '',
          'Line Alias': account?.lineAlias || '',
          Status: account?.ACNT_STATUS_REF || '',
          'Sales Rep': account?.salesRep || '',
          CSR: account?.CSR_NAME || '',
          Activation: getActivationDate(account) || '',
          Plan: account?.callPlan || '',
          Contact: formatContactForAccount(account) || '',
          Affiliate: account?.affiliate || '',
        };
      });
      exportJsonDataToCSVFile({ jsonData: data, filename: 'Accounts' });
    } catch {
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Oops, something went wrong while downloading file. Please contact IT.',
        }),
      );
    }
  };

  return (
    <StyledRoot className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.controlPaper}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              disabled={
                accountNumber.length > 0 ||
                tollFree.length > 0 ||
                affiliate.length > 0 ||
                receiverID.length > 0
              }
              error={accountName.length > 0 && accountName.length < 3}
              helperText="min 3 characters"
              id="standard-helperText1"
              label="Account Name"
              name="accountName"
              onChange={handleTextChange}
              value={accountName}
              variant="standard"
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              disabled={
                accountName.length > 0 ||
                tollFree.length > 0 ||
                affiliate.length > 0 ||
                receiverID.length > 0
              }
              helperText=" "
              id="standard-helperText2"
              label="Account Number"
              name="accountNumber"
              onChange={handleTextChange}
              value={accountNumber}
              variant="standard"
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ContactPhoneIcon />
                  </InputAdornment>
                ),
              }}
              disabled={
                accountName.length > 0 ||
                accountNumber.length > 0 ||
                affiliate.length > 0 ||
                receiverID.length > 0
              }
              helperText=" "
              id="standard-helperText3"
              label="Toll Free Number"
              name="tollFree"
              onChange={handleTextChange}
              value={tollFree}
              variant="standard"
            />

            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ContactPhoneIcon />
                  </InputAdornment>
                ),
              }}
              disabled={accountName.length > 0 || accountNumber.length > 0 || tollFree.length > 0}
              helperText="Enter 10 digits"
              id="standard-helperText3"
              label="Receiver"
              name="receiverID"
              onChange={handleTextChange}
              value={receiverID}
              variant="standard"
            />

            {props.mode !== 'search' && props.mode !== 'callmask' && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
                disabled={accountName.length > 0 || accountNumber.length > 0 || tollFree.length > 0}
                helperText=" "
                id="standard-helperText3"
                label="Affiliate"
                name="affiliate"
                onChange={handleTextChange}
                value={affiliate}
                variant="standard"
              />
            )}
            {props?.showFilterCancelledAccountsCheckbox && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayCancelledAccounts}
                    inputProps={{ 'aria-label': 'Display Cancelled Accounts' }}
                    onChange={handleDisplayCancelledAccount}
                  />
                }
                label="Show Cancelled Accounts"
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <PortalButton color="primary" onClick={handleClick} variant="contained">
                Search
              </PortalButton>
              <PortalButton color="secondary" onClick={resetSearch} variant="contained">
                Reset
              </PortalButton>

              {filteredSearchAccounts && filteredSearchAccounts?.length > 0 && (
                <PortalButton
                  component="span"
                  data-tour="rtDownloadMsg"
                  onClick={handleDownloads}
                  startIcon={<GetAppIcon />}
                  variant="contained"
                >
                  {t('Download')}
                </PortalButton>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {filteredSearchAccounts && filteredSearchAccounts.length > 0 && (
            <div>
              <TableContainer component={Paper}>
                <Table aria-label="dense table" className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.tableHead}>
                      <TableCell sx={{ pl: 4 }}>
                        <TableSortLabel
                          active={sortKey === 'ACNT_NUM'}
                          classes={{
                            active: classes.active,
                            icon: classes.icon,
                            root: classes.sortCell,
                          }}
                          direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                          onClick={() => createSortHandler('ACNT_NUM')}
                        >
                          Account
                        </TableSortLabel>
                      </TableCell>

                      <TableCell align="center">
                        <TableSortLabel
                          active={sortKey === 'CMPNY_NAME_TXT'}
                          classes={{
                            active: classes.active,
                            icon: classes.icon,
                            root: classes.sortCell,
                          }}
                          direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                          onClick={() => createSortHandler('CMPNY_NAME_TXT')}
                        >
                          Account Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={sortKey === 'ACQ_COMPANY'}
                          classes={{
                            active: classes.active,
                            icon: classes.icon,
                            root: classes.sortCell,
                          }}
                          direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                          onClick={() => createSortHandler('ACQ_COMPANY')}
                        >
                          Brand/Acquisition
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">Parent Line</TableCell>
                      {props.mode != 'callmask' && <TableCell align="center">Line Alias</TableCell>}
                      {props.mode != 'callmask' && (
                        <TableCell align="center">
                          <TableSortLabel
                            active={sortKey === 'ACNT_STATUS_REF'}
                            classes={{
                              active: classes.active,
                              icon: classes.icon,
                              root: classes.sortCell,
                            }}
                            direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                            onClick={() => createSortHandler('ACNT_STATUS_REF')}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {/* {props.mode == 'callmask' &&
                                            <TableCell align="center">
                                                Twilio Number
                                            </TableCell>} */}
                      {props.mode == 'callmask' && <TableCell align="center">Caller ID</TableCell>}
                      {props.mode == 'callmask' && (
                        <TableCell align="center">Messaging Status</TableCell>
                      )}
                      {props.mode !== 'search' && props.mode !== 'callmask' && (
                        <>
                          <TableCell align="center">
                            <TableSortLabel
                              active={sortKey === 'salesRep'}
                              classes={{
                                active: classes.active,
                                icon: classes.icon,
                                root: classes.sortCell,
                              }}
                              direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                              onClick={() => createSortHandler('salesRep')}
                            >
                              Sales Rep
                            </TableSortLabel>
                          </TableCell>
                          <TableCell align="center">
                            <TableSortLabel
                              active={sortKey === 'CSR_NAME'}
                              classes={{
                                active: classes.active,
                                icon: classes.icon,
                                root: classes.sortCell,
                              }}
                              direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                              onClick={() => createSortHandler('CSR_NAME')}
                            >
                              CSR
                            </TableSortLabel>
                          </TableCell>
                          <TableCell align="center">
                            <TableSortLabel
                              active={sortKey === 'ACTIVATE_DATE'}
                              classes={{
                                active: classes.active,
                                icon: classes.icon,
                                root: classes.sortCell,
                              }}
                              direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                              onClick={() => createSortHandler('ACTIVATE_DATE')}
                            >
                              Activation
                            </TableSortLabel>
                          </TableCell>
                          {/* <TableCell align="center">
                            <TableSortLabel
                              active={sortKey === 'CANCELLATION_DATE'}
                              classes={{
                                active: classes.active,
                                icon: classes.icon,
                                root: classes.sortCell,
                              }}
                              direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                              onClick={() => createSortHandler('CANCELLATION_DATE')}
                            >
                              {'Cancel'}
                            </TableSortLabel>
                          </TableCell> */}
                          <TableCell align="center">Plan</TableCell>
                          <TableCell align="center">
                            <TableSortLabel
                              active={sortKey === 'SRVC_CNTCT_FIRST_NAME_TXT'}
                              classes={{
                                active: classes.active,
                                icon: classes.icon,
                                root: classes.sortCell,
                              }}
                              direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                              onClick={() => createSortHandler('SRVC_CNTCT_FIRST_NAME_TXT')}
                            >
                              Contact
                            </TableSortLabel>
                          </TableCell>
                          <TableCell align="center">
                            <TableSortLabel
                              active={sortKey === 'affiliate'}
                              classes={{
                                active: classes.active,
                                icon: classes.icon,
                                root: classes.sortCell,
                              }}
                              direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                              onClick={() => createSortHandler('affiliate')}
                            >
                              Affiliate
                            </TableSortLabel>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredSearchAccounts &&
                      filteredSearchAccounts.length > 0 &&
                      filteredSearchAccounts
                        .slice()
                        .sort((a, b) =>
                          sortKey === 'ACTIVATE_DATE'
                            ? sortOrder === 'asc'
                              ? a['CONV_SRVC_START_DATE']
                                ? b['CONV_SRVC_START_DATE']
                                  ? moment(a['CONV_SRVC_START_DATE']).diff(
                                      moment(b['CONV_SRVC_START_DATE']),
                                    ) === 0
                                    ? 0
                                    : moment(a['CONV_SRVC_START_DATE']).diff(
                                          moment(b['CONV_SRVC_START_DATE']),
                                        ) > 0
                                      ? 1
                                      : -1
                                  : moment(a['CONV_SRVC_START_DATE']).diff(
                                        moment(b[sortKey], 'YYYYMMDDHHmmss'),
                                      ) === 0
                                    ? 0
                                    : moment(a['CONV_SRVC_START_DATE']).diff(
                                          moment(b[sortKey], 'YYYYMMDDHHmmss'),
                                        ) > 0
                                      ? 1
                                      : -1
                                : b['CONV_SRVC_START_DATE']
                                  ? moment(a[sortKey], 'YYYYMMDDHHmmss').diff(
                                      moment(b['CONV_SRVC_START_DATE']),
                                    ) === 0
                                    ? 0
                                    : moment(a[sortKey], 'YYYYMMDDHHmmss').diff(
                                          moment(b['CONV_SRVC_START_DATE']),
                                        ) > 0
                                      ? 1
                                      : -1
                                  : moment(a[sortKey], 'YYYYMMDDHHmmss').diff(
                                        moment(b[sortKey], 'YYYYMMDDHHmmss'),
                                      ) === 0
                                    ? 0
                                    : moment(a[sortKey], 'YYYYMMDDHHmmss').diff(
                                          moment(b[sortKey], 'YYYYMMDDHHmmss'),
                                        ) > 0
                                      ? 1
                                      : -1
                              : a['CONV_SRVC_START_DATE']
                                ? b['CONV_SRVC_START_DATE']
                                  ? moment(b['CONV_SRVC_START_DATE']).diff(
                                      moment(a['CONV_SRVC_START_DATE']),
                                    ) === 0
                                    ? 0
                                    : moment(b['CONV_SRVC_START_DATE']).diff(
                                          moment(a['CONV_SRVC_START_DATE']),
                                        ) > 0
                                      ? 1
                                      : -1
                                  : moment(b[sortKey], 'YYYYMMDDHHmmss').diff(
                                        moment(a['CONV_SRVC_START_DATE']),
                                      ) === 0
                                    ? 0
                                    : moment(b[sortKey], 'YYYYMMDDHHmmss').diff(
                                          moment(a['CONV_SRVC_START_DATE']),
                                        ) > 0
                                      ? 1
                                      : -1
                                : b['CONV_SRVC_START_DATE']
                                  ? moment(b['CONV_SRVC_START_DATE']).diff(
                                      moment(a[sortKey], 'YYYYMMDDHHmmss'),
                                    ) === 0
                                    ? 0
                                    : moment(b['CONV_SRVC_START_DATE']).diff(
                                          moment(a[sortKey], 'YYYYMMDDHHmmss'),
                                        ) > 0
                                      ? 1
                                      : -1
                                  : moment(b[sortKey], 'YYYYMMDDHHmmss').diff(
                                        moment(a[sortKey], 'YYYYMMDDHHmmss'),
                                      ) === 0
                                    ? 0
                                    : moment(b[sortKey], 'YYYYMMDDHHmmss').diff(
                                          moment(a[sortKey], 'YYYYMMDDHHmmss'),
                                        ) > 0
                                      ? 1
                                      : -1
                            : a[sortKey] && b[sortKey] && a[sortKey] !== null && b[sortKey] !== null
                              ? sortOrder === 'asc'
                                ? a[sortKey].localeCompare(b[sortKey])
                                : b[sortKey].localeCompare(a[sortKey])
                              : a[sortKey] === null
                                ? 1
                                : -1,
                        )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                            <PortalTableRow
                              className={
                                row.ACNT_NUM === selSearchAcctNum
                                  ? classes.selectedRow
                                  : classes.row
                              }
                              hover
                              key={row.number}
                              onClick={(event) => handleRowClick(event, row.ACNT_NUM)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell component="th" scope="row" sx={{ pl: 4 }}>
                                {row.ACNT_NUM}
                              </TableCell>
                              <TableCell align="center">{row.CMPNY_NAME_TXT}</TableCell>
                              <TableCell align="center">{row.ACQ_COMPANY}</TableCell>
                              <TableCell align="center">{row.tollFreeNumber}</TableCell>
                              {props.mode != 'callmask' && (
                                <TableCell align="center">{row.lineAlias}</TableCell>
                              )}
                              {props.mode != 'callmask' && (
                                <TableCell align="center">{row.ACNT_STATUS_REF}</TableCell>
                              )}
                              {/* {props.mode == 'callmask' && (
                                <TableCell align="center">
                                  {JSON.parse(row.twilioNumber).map((val) => {
                                    if (val.type == 1) {
                                      return <p>{val.phoneNumber}</p>;
                                    }
                                  })}
                                </TableCell>
                              )} */}
                              {props.mode == 'callmask' && (
                                <TableCell align="center">
                                  {JSON.parse(row.twilioNumber).length > 0 ? (
                                    JSON.parse(row.twilioNumber).map((val, index) => {
                                      if (val.type == 2 && val.verificationStatus == 'VERIFIED') {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <p>{val.phoneNumber}</p>
                                            <EditIcon
                                              onClick={() => handleEditCallerIdOpen(row)}
                                              style={{ marginLeft: '4' }}
                                            />
                                          </div>
                                        );
                                      } else if (
                                        val.type == 2 &&
                                        val.verificationStatus == 'PENDING'
                                      ) {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <p>{val.verificationStatus}</p>
                                            <EditIcon
                                              onClick={() => handleEditCallerIdOpen(row)}
                                              style={{ marginLeft: '4' }}
                                            />
                                          </div>
                                        );
                                      } else if (val.verificationStatus == 'DISABLED') {
                                        return (
                                          <PortalButton
                                            key={index}
                                            onClick={() => handleAddVerifiedCallerId(row)}
                                            variant="outlined"
                                          >
                                            ENABLE
                                          </PortalButton>
                                        );
                                      }
                                      return null;
                                    })
                                  ) : (
                                    <PortalButton
                                      onClick={() => handleAddVerifiedCallerId(row)}
                                      variant="outlined"
                                    >
                                      ENABLE
                                    </PortalButton>
                                  )}
                                </TableCell>
                              )}
                              {props.mode == 'callmask' && (
                                <TableCell align="center">
                                  {JSON.parse(row.textrequestNumbers).length > 0 ? (
                                    JSON.parse(row.textrequestNumbers).map((val, index) => {
                                      if (
                                        val.verificationStatus == 'VERIFIED' ||
                                        val.verificationStatus == 'PENDING'
                                      ) {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {val.verificationStatus == 'VERIFIED' ? (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <p>{val.phoneNumber}</p>
                                                <EditIcon
                                                  onClick={() => handleTextRequestOpen(row)}
                                                  style={{ marginLeft: '4' }}
                                                />{' '}
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <p>{val.verificationStatus}</p>
                                                <EditIcon
                                                  onClick={() => handleTextRequestOpen(row)}
                                                  style={{ marginLeft: '4' }}
                                                />{' '}
                                              </div>
                                            )}
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <PortalButton
                                            key={index}
                                            onClick={() => handleAddTextRequestMessaging(row)}
                                            variant="outlined"
                                          >
                                            ENABLE
                                          </PortalButton>
                                        );
                                      }
                                    })
                                  ) : (
                                    <PortalButton
                                      onClick={() => handleAddTextRequestMessaging(row)}
                                      variant="outlined"
                                    >
                                      ENABLE
                                    </PortalButton>
                                  )}
                                </TableCell>
                              )}
                              {props.mode !== 'search' && props.mode !== 'callmask' && (
                                <>
                                  <TableCell align="center">{row.salesRep}</TableCell>
                                  <TableCell align="center">{row.CSR_NAME}</TableCell>
                                  <TableCell align="center">{getActivationDate(row)}</TableCell>
                                  {/* <TableCell align="center">{row.CANCELLATION_DATE}</TableCell> */}
                                  <TableCell align="center">{row.callPlan}</TableCell>
                                  <TableCell align="center">
                                    <Typography sx={{ whiteSpace: 'pre-line' }}>
                                      {formatContactForAccount(row)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">{row.affiliate}</TableCell>
                                </>
                              )}
                            </PortalTableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                ActionsComponent={TablePaginationActions}
                component="div"
                count={filteredSearchAccounts ? filteredSearchAccounts.length : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500, 1000]}
              />
            </div>
          )}
          {apiSuccess && filteredSearchAccounts && filteredSearchAccounts.length === 0 && (
            <Box className={classes.noRecordsBox}>
              <Typography>No Results Found.</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleEditCallerIdClose}
        open={editCallerIdOpen}
      >
        <DialogTitle id="alert-dialog-title">Account Verified Caller ID</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to remove Caller ID option from this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PortalButton
            color="primary"
            onClick={() => disableVerifiedCallerId('DISABLED')}
            variant="contained"
          >
            Confirm
          </PortalButton>
          <PortalButton
            autoFocus
            color="secondary"
            onClick={handleEditCallerIdClose}
            variant="contained"
          >
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleTextRequestClose}
        open={editTextRequestOpen}
      >
        <DialogTitle id="alert-dialog-title">Account Text Messaging Number</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to remove Text messaging option from this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PortalButton color="primary" onClick={disableTextRequest} variant="contained">
            Confirm
          </PortalButton>
          <PortalButton
            autoFocus
            color="secondary"
            onClick={handleTextRequestClose}
            variant="contained"
          >
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>
      {showEditDialog && (
        <TextMessageEditDialog open={showEditDialog} propagateClose={handleCloseEditDialog} />
      )}
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">Add Verified Caller ID</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Parent Line</InputLabel>
            <Select
              fullWidth
              id="vc-type-labelselector"
              label="Choose Number"
              labelId="vc-type-label"
              name="chooseNumber"
              onChange={handleChange}
              value={choosedAccount.tollFreeNumber}
            >
              <MenuItem value={choosedAccount.tollFreeNumber}>
                {choosedAccount.tollFreeNumber}
              </MenuItem>
              {/* {selectedAccount &&
                selectedAccount.twilioNumber &&
                selectedAccount.twilioNumber.split(',').map((val) => {
                  return <MenuItem value={val}>{val}</MenuItem>;
                })} */}
            </Select>
            <TextField
              error={callerIdError}
              fullWidth
              helperText={callerIdhelperText}
              label="Caller ID"
              name="phoneNumber"
              onChange={handleChange}
              value={phoneNumber}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={messagingEnabled}
                  onChange={handleChange}
                  name="messagingEnabled"
                />
              }
              color="primary"
              label="Enable Messaging"
            /> */}
          </FormControl>

          {/* <FormControl className={classes.formControl}>
            <Select
              labelId="vc-type-label"
              id="vc-type-labelselector"
              name="type"
              value={type}
              fullWidth
              onChange={handleChange}
            >
              <MenuItem value="1">1 - Account</MenuItem>
              <MenuItem value="2">2- User</MenuItem>
            </Select>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <PortalButton color="primary" onClick={verifyCaller} variant="contained">
            Save
          </PortalButton>
          <PortalButton color="secondary" onClick={handleClose} variant="contained">
            Cancel
          </PortalButton>
        </DialogActions>
      </Dialog>
      <CustomizedProgressLoader />
    </StyledRoot>
  );
}
