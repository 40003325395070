export const RegexPatterns = {
  USMobilePhoneNumber:
    /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
  // Basic email validation regex pattern:
  // - Ensures one or more non-whitespace/non-'@' characters before '@'
  // - Requires a single '@' symbol
  // - Ensures one or more non-whitespace/non-'@' characters after '@'
  // - Requires a single '.' symbol
  // - Ensures one or more non-whitespace/non-'@' characters after '.'
  SuggestionFormEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
};
