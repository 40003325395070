import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import {
  Autocomplete,
  ButtonGroup,
  Card,
  CardHeader,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  getIvrMessages,
  refetchVoicemailsSelector,
  setIvrMessages,
  setRefetchVoicemails,
  setSelectedVoicemailLineNumber,
  setVoicemailGridDisplayType,
} from './voicemailSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { accountSelector, selAccountSelector } from '../accountSlice';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ListIcon from '@mui/icons-material/List';
import PortalButton from '../../shared/components/PortalButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  getUtcFormattedEndOfDayDate,
  getUtcFormattedStartOfDayDate,
  IvrMessageDateRangeDefaultDays,
  ivrMessageDateRangeStaticRanges,
} from '../../../utils/ivrMessageUtil';
import PortalDateRangePicker from '../../shared/components/PortalDateRangePicker';
import { getDefaultDateRangeObject } from '../../../utils/dateUtil';

const PREFIX = 'VoiceMailMainFilter';

const classes = {};

const StyledGrid = styled(Grid)(({ theme }) => ({}));

const getDefaultSelectionDateRange = () => {
  return getDefaultDateRangeObject(
    moment().subtract(IvrMessageDateRangeDefaultDays, 'd').toDate(),
    new Date(),
  );
};

export default function VoiceMailMainFilter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lineNumbers, setLineNumbers] = useState([]);
  const [displayType, setDisplayType] = useState('grid');
  const selAccount = useSelector(selAccountSelector);
  const refetchVoicemails = useSelector(refetchVoicemailsSelector);
  const accounts = useSelector(accountSelector);

  const [dateRange, setDateRange] = useState(getDefaultSelectionDateRange());

  const [selectedLineNumber, setSelectedLineNumber] = useState(null);

  useEffect(() => {
    if (refetchVoicemails) {
      dispatch(setRefetchVoicemails(false));
      getSelectedLineNumberMessages();
    }
  }, [refetchVoicemails]);

  useEffect(() => {
    dispatch(setIvrMessages([]));
  }, []);

  useEffect(() => {
    getSelectedLineNumberMessages();
  }, [selectedLineNumber]);

  useEffect(() => {
    dispatch(setVoicemailGridDisplayType(displayType));
  }, [displayType]);

  useEffect(() => {
    if (accounts && accounts?.length > 0 && selAccount) {
      const currentAccount = accounts.find((element) => element.number === parseInt(selAccount));
      if (
        currentAccount &&
        currentAccount?.serviceItems &&
        currentAccount?.serviceItems?.length > 0
      ) {
        const voiceMailLineNumbers = currentAccount?.serviceItems?.filter(
          (item) => item?.type === 'ANSWR' && item?.status === 'ACT',
        );
        setLineNumbers(voiceMailLineNumbers);
        if (voiceMailLineNumbers && voiceMailLineNumbers?.length > 0) {
          setSelectedLineNumber(voiceMailLineNumbers[0]);
        }
      } else {
        setLineNumbers([]);
        setSelectedLineNumber(null);
      }
    }
  }, [accounts, selAccount]);

  const handleDisplayChange = (value) => {
    setDisplayType(value);
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
    dispatch(
      getIvrMessages(
        selectedLineNumber.number,
        getUtcFormattedStartOfDayDate(value.startDate),
        getUtcFormattedEndOfDayDate(value.endDate),
      ),
    );
  };

  const getSelectedLineNumberMessages = () => {
    if (selectedLineNumber && selectedLineNumber?.number) {
      dispatch(setSelectedVoicemailLineNumber(selectedLineNumber));
      fetchIvrMessages(selectedLineNumber.number);
    }
  };

  const fetchIvrMessages = (lineNumber) => {
    const range = dateRange || getDefaultSelectionDateRange();
    if (lineNumber && range) {
      const startDate = getUtcFormattedStartOfDayDate(range.startDate);
      const endDate = getUtcFormattedEndOfDayDate(range.endDate);
      dispatch(getIvrMessages(lineNumber, startDate, endDate));
    } else {
      dispatch(getIvrMessages([]));
    }
  };

  const handleLineNumberChange = (value) => {
    setSelectedLineNumber(value);
  };

  const handleRefreshIvrMessages = () => {
    if (selectedLineNumber && selectedLineNumber.number) {
      fetchIvrMessages(selectedLineNumber.number);
    }
  };

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={<PhoneCallbackIcon />}
            className={classes.cardHeader}
            title={
              <Grid className={classes.headHolder} container spacing={1}>
                <Grid item md={5} xs={12}>
                  {lineNumbers && (
                    <Autocomplete
                      data-tour="rtSelLineNumber"
                      fullWidth
                      getOptionLabel={(option) => option.number || ''}
                      id="select-lineNumber-autocomplete"
                      onChange={(event, newValue) => {
                        handleLineNumberChange(newValue);
                      }}
                      options={
                        lineNumbers && lineNumbers?.length > 0
                          ? lineNumbers.slice().sort((a, b) => a.number)
                          : []
                      }
                      renderInput={(params) => {
                        if (params) {
                          return (
                            <TextField
                              {...params}
                              label={t('Select LineNumber')}
                              variant="outlined"
                            />
                          );
                        }
                      }}
                      style={{ flex: 1, marginRight: '8px', background: 'white' }}
                      value={selectedLineNumber}
                    />
                  )}
                </Grid>
                <Grid item md={5} xs={12}>
                  <ButtonGroup color="primary">
                    <IconButton
                      aria-label="upload picture"
                      color={displayType === 'grid' ? 'secondary' : 'primary'}
                      component="span"
                      onClick={() => handleDisplayChange('grid')}
                      size="medium"
                      variant="contained"
                    >
                      <Tooltip disableFocusListener title="Grid">
                        <ViewComfyIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      aria-label="upload picture"
                      color={displayType === 'list' ? 'secondary' : 'primary'}
                      component="span"
                      onClick={() => handleDisplayChange('list')}
                      size="medium"
                      variant="contained"
                    >
                      <Tooltip disableFocusListener title="List">
                        <ListIcon />
                      </Tooltip>
                    </IconButton>

                    <PortalDateRangePicker
                      dateRange={dateRange}
                      maxAllowedDays={7}
                      propagateDateRangeChange={handleDateRangeChange}
                      staticRanges={ivrMessageDateRangeStaticRanges}
                    />
                    {dateRange ? (
                      <Typography sx={{ alignSelf: 'center' }} variant="caption">
                        {moment(dateRange.startDate)
                          .startOf('day')
                          .format('MMM DD, YYYY hh:mm A z')}{' '}
                        - {moment(dateRange.endDate).endOf('day').format('MMM DD, YYYY hh:mm A z')}
                      </Typography>
                    ) : null}
                  </ButtonGroup>
                </Grid>
                <Grid item md={2} xs={12}>
                  <PortalButton
                    component="span"
                    onClick={handleRefreshIvrMessages}
                    startIcon={<RefreshIcon />}
                    transition={{ type: 'spring', stiffness: 500 }}
                    variant="contained"
                  >
                    {t('Retrieve Voicemails')}
                  </PortalButton>
                </Grid>
              </Grid>
            }
          />
        </Card>{' '}
      </Grid>
    </StyledGrid>
  );
}
