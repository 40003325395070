import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Zoom,
  useTheme,
  CircularProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Grid, InputAdornment, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import { accountSelector, getAccountsByUser } from '../accounts/accountSlice';
import {
  auth0Loading,
  getPortalUser,
  getTimezones,
  portalUserSelector,
  setPortalSnackInfo,
  timezonesSelector,
  updatePortalUser,
  updatePortalUserPassword,
} from '../../app/authSlice';
import { useDispatch, useSelector } from 'react-redux';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountVerifiedCallerIds from './AccountVerifiedCallerIds';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomizeLabels from './CustomizeLabels';
import CustomizedProgressLoader from '../shared/CustomizedProgressLoader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendIcon from '@mui/icons-material/Send';
import Settings from './Settings';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { domainDefaultsSelector } from '../../brandSlice';
import moment from 'moment';
import { sendSuggestionEmail } from './phoneBookSlice';
import { setCustomizedProgressLoading } from '../../slices/customizedProgressLoaderSlice';
import { Warning } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';
import { RegexPatterns } from '../../utils/regex';

const PREFIX = 'Profile';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  card: `${PREFIX}-card`,
  cardActions: `${PREFIX}-cardActions`,
  cardHeader: `${PREFIX}-cardHeader`,
  suggestion: `${PREFIX}-suggestion`,
  fab: `${PREFIX}-fab`,
  textField: `${PREFIX}-textField`,
  form: `${PREFIX}-form`,
};

const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(3),
  },

  [`& .${classes.cardActions}`]: {
    display: 'flex',
    margin: theme.spacing(1),
    justifyContent: 'center',
  },

  [`& .${classes.cardHeader}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.suggestion}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.fab}`]: {
    position: 'absolute',
    top: theme.spacing(10),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    right: theme.spacing(2),
  },

  [`& .${classes.textField}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
}));

export default function Profile() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const portalUser = useSelector(portalUserSelector);
  const [timezone, setTimezone] = useState('US/Eastern');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [passwordComplexFlag1, setPasswordComplexFlag1] = useState(false);
  const [passwordComplexFlag2, setPasswordComplexFlag2] = useState(false);
  const [passwordComplexFlag3, setPasswordComplexFlag3] = useState(false);
  const [mfaValue, setMfaValue] = useState('');
  const [feedback, setFeedback] = useState('');
  const brand = useSelector(domainDefaultsSelector);
  const timezones = useSelector(timezonesSelector);
  const loading = useSelector(auth0Loading);
  const accounts = useSelector(accountSelector);
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const sendEmail = () => {
    if (customerEmailIsValid()) {
      const msg = `Name: ${customerName} \n Email: ${customerEmail} \n Message: ${feedback}`;
      dispatch(sendSuggestionEmail(msg, brand.suggestionEmail, brand.name));
      setOpen(true);
      closeSuggestionDialog();
    } else {
      dispatch(
        setPortalSnackInfo({ severity: 'error', message: 'Please enter valid email address.' }),
      );
    }
  };

  useEffect(() => {
    if (portalUser && portalUser.mfa && portalUser.mfa === 'true') {
      setMfaValue(true);
    } else {
      setMfaValue(false);
    }
  }, [portalUser]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const closeSuggestionDialog = () => {
    setFeedback('');
    setCustomerName('');
    setCustomerEmail('');
    setOpen(false);
  };

  const checkPasswordStrength = (password) => {
    const n = password.length;
    let hasLower = false,
      hasUpper = false,
      hasDigit = false,
      specialChar = false;
    const specialChars = /[(!@#$%^&*)]/;

    if (password.toUpperCase() != password) hasLower = true;
    if (password.toLowerCase() != password) hasUpper = true;
    if (/[0-9]/.test(password)) hasDigit = true;
    if (specialChars.test(password)) specialChar = true;

    if (hasDigit && hasLower && hasUpper) {
      setPasswordComplexFlag1(true);
    } else {
      setPasswordComplexFlag1(false);
    }
    if (specialChar) {
      setPasswordComplexFlag2(true);
    } else {
      setPasswordComplexFlag2(false);
    }
    if (password.length > 7) {
      setPasswordComplexFlag3(true);
    } else {
      setPasswordComplexFlag3(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setCustomizedProgressLoading(true));
      dispatch(getAccountsByUser());
      dispatch(getTimezones());
      dispatch(getPortalUser());
      dispatch(setCustomizedProgressLoading(false));
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (portalUser && portalUser.login) {
      setEmail(portalUser.email);
      setFirstName(portalUser.firstName);
      setLastName(portalUser.lastName);
      setTimezone(portalUser.timeZone);
    }
  }, [portalUser]);

  const handleSave = () => {
    var user = {
      password: null,
      firstName: null,
      lastName: null,
      email: email,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: null,
      mfa: null,
    };
    var options = JSON.parse(portalUser.optionsJson);
    user.options = JSON.stringify(options);
    user.firstName = firstName.length > 0 ? firstName : portalUser.firstName;
    user.lastName = lastName.length > 0 ? lastName : portalUser.lastName;
    user.timeZone = timezone.length > 0 ? timezone : portalUser.timeZone;
    user.mfa = mfaValue;
    dispatch(updatePortalUser(user));
  };

  const handlePasswordSave = () => {
    var user = {
      password: password,
      firstName: null,
      lastName: null,
      email: email,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: null,
    };
    var options = JSON.parse(portalUser.optionsJson);
    user.options = JSON.stringify(options);
    user.firstName = firstName.length > 0 ? firstName : portalUser.firstName;
    user.lastName = lastName.length > 0 ? lastName : portalUser.lastName;
    user.timeZone = timezone.length > 0 ? timezone : portalUser.timeZone;
    dispatch(updatePortalUserPassword(user));
    setPassword('');
    setConfirmPassword('');
    setPasswordComplexFlag1(false);
    setPasswordComplexFlag2(false);
    setPasswordComplexFlag3(false);
  };

  const handlePasswordCancel = () => {
    setPassword('');
    setConfirmPassword('');
    setPasswordComplexFlag1(false);
    setPasswordComplexFlag2(false);
    setPasswordComplexFlag3(false);
  };

  const handleChange = (event) => {
    switch (event.currentTarget?.name) {
      case 'firstName':
        setFirstName(event.target.value);
        break;
      case 'lastName':
        setLastName(event.target.value);
        break;
      case 'timezone':
        setTimezone(event.target.value);
        break;
      case 'customerName':
        setCustomerName(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        checkPasswordStrength(event.target.value);
        setPassword(event.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(event.target.value);
        break;
      case 'customerEmail':
        setCustomerEmail(event.target.value);
        break;
      case 'feedback':
        setFeedback(event.target.value);
        break;
      case 'mfa':
        setMfaValue(event.target.value);
        break;
      default:
        setTimezone(event.target.value);
    }
  };

  const handleMfaChange = (event) => {
    setMfaValue(event.target.checked);
  };

  const customerEmailIsValid = () => {
    return RegexPatterns.SuggestionFormEmail.test(customerEmail);
  };

  const formIsInvalid = () => {
    return !customerName || !customerEmail || !feedback || !customerEmailIsValid();
  };

  const showCustomerEmailInvalidError = () => {
    return customerEmail !== '' && !customerEmailIsValid();
  };

  return (
    <StyledRoot>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12}>
          <Zoom
            in={true}
            sx={{
              transitionDelay: '1000ms',
            }}
          >
            <Fab className={classes.fab} color="secondary" onClick={handleOpen} variant="extended">
              <EditIcon /> {t('Suggestion')}
            </Fab>
          </Zoom>
        </Grid>

        <Grid item sx={{ marginTop: theme.spacing(2) }} xs={12}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={<AccountCircleIcon />}
                  className={classes.cardHeader}
                  subheader={<Typography />}
                  title={t('Web Account Info')}
                />
                <CardContent>
                  {loading && <CircularProgress />}
                  <TableContainer dense>
                    <Table aria-label="a dense table" className={classes.table} size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" className={classes.form}>
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className={classes.textField}
                              disabled={portalUser && portalUser.idpuser !== null}
                              fullWidth
                              inputProps={{ maxLength: 50 }}
                              label={t('First Name')}
                              name="firstName"
                              onChange={handleChange}
                              type="text"
                              value={firstName}
                              variant="outlined"
                            />

                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className={classes.textField}
                              disabled={portalUser && portalUser.idpuser !== null}
                              fullWidth
                              inputProps={{ maxLength: 50 }}
                              label={t('Last Name')}
                              name="lastName"
                              onChange={handleChange}
                              type="text"
                              value={lastName}
                              variant="outlined"
                            />

                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className={classes.textField}
                              disabled={portalUser && portalUser.idpuser !== null}
                              fullWidth
                              label={t('Email')}
                              name="email"
                              onChange={handleChange}
                              type="email"
                              value={portalUser && email.length === 0 ? portalUser.email : email}
                              variant="outlined"
                            />
                            <TextField
                              className={classes.textField}
                              fullWidth
                              id="demo-simple-select"
                              label={t('Timezone')}
                              labelId="demo-simple-select-label"
                              name="timezone"
                              onChange={handleChange}
                              select
                              value={
                                portalUser && portalUser.timeZone && timezone.length === 0
                                  ? portalUser.timeZone
                                  : timezone
                              }
                            >
                              {timezones &&
                                timezones.map((item) => (
                                  <MenuItem key={item.KEY_TXT} value={item.KEY_TXT}>
                                    {item.DESCRIPTION_TXT}
                                  </MenuItem>
                                ))}
                            </TextField>
                            {((portalUser &&
                              portalUser.passwordPolicy &&
                              portalUser.passwordPolicy.length > 0 &&
                              portalUser.passwordPolicy[0].mfaPolicyStartDate &&
                              portalUser.passwordPolicy[0].mfaPolicyEndDate &&
                              moment(portalUser.passwordPolicy[0].mfaPolicyStartDate).diff(
                                moment(),
                                'days',
                              ) <= 0 &&
                              moment(portalUser.passwordPolicy[0].mfaPolicyEndDate).diff(
                                moment(),
                                'days',
                              ) >= 0) ||
                              portalUser) &&
                            portalUser.idpuser === null ? (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={mfaValue}
                                    color="primary"
                                    name="mfa"
                                    onChange={handleMfaChange}
                                  />
                                }
                                label="Multi Factor Authentication (MFA)"
                                sx={{ marginTop: '20px' }}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <div>
                    <PortalButton color="primary" onClick={handleSave} variant="contained">
                      {t('Save')}
                    </PortalButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
            <Grid item md={6} xs={12}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={<AccountCircleIcon />}
                  className={classes.cardHeader}
                  subheader={
                    <div>
                      <Typography sx={{ color: 'red' }}>
                        {password !== confirmPassword
                          ? '* New password & confirm password should match'
                          : ''}
                      </Typography>
                    </div>
                  }
                  title={t('Change Password')}
                />
                <CardContent>
                  <List>
                    {portalUser && portalUser.idpuser !== null && (
                      <ListItem>
                        <ListItemIcon>
                          <Warning sx={{ color: 'orange' }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography sx={{ fontVariant: 'bold' }}>
                            Change Password not available for SSO user
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    )}
                    <ListItem>
                      <ListItemIcon>
                        {passwordComplexFlag1 ? (
                          <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                        ) : (
                          <HighlightOffIcon sx={{ color: 'red' }} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Minimum of one lower case (a-z), one upper case (A-Z) and one number (0-9)" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        {passwordComplexFlag2 ? (
                          <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                        ) : (
                          <HighlightOffIcon sx={{ color: 'red' }} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Minimum of one special character (!@#$%^&*)" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        {passwordComplexFlag3 ? (
                          <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                        ) : (
                          <HighlightOffIcon sx={{ color: 'red' }} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Must have 8 characters in length (Max length supported is 50 characters)" />
                    </ListItem>
                  </List>
                  {loading && <CircularProgress />}
                  <TableContainer dense>
                    <Table aria-label="a dense table" className={classes.table} size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" className={classes.form}>
                            <TextField
                              InputProps={{
                                shrink: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <PortalIconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </PortalIconButton>
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.textField}
                              disabled={portalUser && portalUser.idpuser !== null}
                              fullWidth
                              inputProps={{ maxLength: 50 }}
                              label={t('New Password')}
                              name="password"
                              onChange={handleChange}
                              type={showPassword ? 'text' : 'password'}
                              value={password}
                              variant="outlined"
                            />

                            <TextField
                              InputProps={{
                                shrink: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <PortalIconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </PortalIconButton>
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.textField}
                              disabled={portalUser && portalUser.idpuser !== null}
                              fullWidth
                              inputProps={{ maxLength: 50 }}
                              label={t('Confirm Password')}
                              name="confirmPassword"
                              onChange={handleChange}
                              type={showConfirmPassword ? 'text' : 'password'}
                              value={confirmPassword}
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <div>
                    <PortalButton
                      color="primary"
                      disabled={
                        password === '' ||
                        confirmPassword === '' ||
                        confirmPassword !== password ||
                        !passwordComplexFlag1 ||
                        !passwordComplexFlag2 ||
                        !passwordComplexFlag3
                      }
                      onClick={handlePasswordSave}
                      sx={{ mr: '8px' }}
                      variant="contained"
                    >
                      {t('Save')}
                    </PortalButton>
                    <PortalButton
                      color="secondary"
                      disabled={portalUser && portalUser.idpuser !== null}
                      onClick={handlePasswordCancel}
                      variant="contained"
                    >
                      {t('Clear')}
                    </PortalButton>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <Settings />
        </Grid>

        <Grid item md={12} xs={12}>
          {accounts &&
            accounts.length > 0 &&
            accounts.find((item) => {
              return (
                item.verifiedCallerId && item.verifiedCallerId.verificationStatus !== 'DISABLED'
              );
            }) &&
            portalUser && <AccountVerifiedCallerIds />}
        </Grid>

        <Grid item md={12} xs={12}>
          <CustomizeLabels />
        </Grid>
      </Grid>

      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="form-dialog-title">{t('Tell us what you think')}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <DialogContentText>{t('Have a suggestion or feedback?')}</DialogContentText>

          <TextField
            fullWidth
            id="standard-multiline-static"
            label={t('Your Name')}
            name="customerName"
            onChange={handleChange}
            required
            value={customerName}
          />

          <TextField
            error={showCustomerEmailInvalidError()}
            fullWidth
            helperText={
              showCustomerEmailInvalidError() ? t('Please enter a valid email address') : ''
            }
            id="standard-multiline-static"
            label={t('Your Email')}
            name="customerEmail"
            onChange={handleChange}
            required
            type="email"
            value={customerEmail}
          />

          <TextField
            fullWidth
            id="standard-multiline-static"
            label={t('Suggestion')}
            multiline
            name="feedback"
            onChange={handleChange}
            placeholder="Write feedback here"
            required
            rows={4}
            value={feedback}
          />
        </DialogContent>
        <DialogActions>
          <Tooltip title={t('Send')}>
            <PortalButton color="primary" disabled={formIsInvalid()}>
              <SendIcon onClick={sendEmail} variant="contained" />
            </PortalButton>
          </Tooltip>
          <PortalButton color="secondary" onClick={closeSuggestionDialog}>
            {t('Close')}
          </PortalButton>
        </DialogActions>
      </Dialog>

      <CustomizedProgressLoader />
    </StyledRoot>
  );
}
