import { Popover, Typography } from '@mui/material';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import IconButton from '@mui/material/IconButton';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useState } from 'react';
import moment from 'moment';
import { getDefaultDateRangeObject } from '../../../utils/dateUtil';

const PortalDateRangePicker = ({
  maxAllowedDays,
  propagateDateRangeChange,
  dateRange,
  staticRanges,
  inputRanges = [],
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [dateRangeError, setDateRangeError] = useState('');
  const openDateRange = Boolean(anchorEl);
  const id = openDateRange ? 'dateRange-popover' : undefined;

  const handleDatePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateRangeOnChange = (ranges) => {
    if (
      moment(ranges.selection.endDate).diff(moment(ranges.selection.startDate), 'days') >
      maxAllowedDays
    ) {
      setDateRangeError(`Cannot load more than ${maxAllowedDays} days`);
      return;
    }
    setDateRangeError('');
    const newDateRange = getDefaultDateRangeObject(
      ranges.selection.startDate,
      ranges.selection.endDate,
    );
    propagateDateRangeChange(newDateRange);
    if (ranges.selection.startDate !== ranges.selection.endDate) {
      setAnchorEl(false);
    }
  };

  return (
    <>
      <IconButton
        aria-label="Date Range"
        color="white"
        component="button"
        onClick={handleDatePopoverClick}
        variant="contained"
      >
        <DateRangeIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={id}
        onClose={() => {
          setAnchorEl(null);
        }}
        open={openDateRange}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangePicker
          inputRanges={inputRanges}
          onChange={handleDateRangeOnChange}
          ranges={[dateRange]}
          showMonthAndYearPickers={true}
          showSelectionPreview={true}
          staticRanges={staticRanges || defaultStaticRanges}
        />
        <Typography color="error" sx={{ textAlign: 'center' }} variant="subtitle1">
          {dateRangeError}
        </Typography>
      </Popover>
    </>
  );
};

export default PortalDateRangePicker;
PortalDateRangePicker.displayName = 'PortalDateRangePicker';
