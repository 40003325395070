import {
  Button,
  Grid,
  InputAdornment,
  Typography,
  Paper,
  Table,
  TableBody,
  TableSortLabel,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Chip,
  Box,
  Tabs,
  Tab,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AccountCircle } from '@mui/icons-material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAuth0UserAsStaff,
  deletePortalUserAsStaff,
  findUsers,
  searchUsersSelector,
  updatePortalUserPasswordAsStaff,
  loadingSelector,
  setSearchUsers,
  chatAffiliationsSelector,
  getAvaialbleAffiliations,
  addChatUserToGroup,
  addCallerIdToWebUser,
  updatePortalUserEmailAsStaff,
  updateUserEmailStatusSelector,
  updatePortalUserMfaAsStaff,
  resetPortalUserMfaAsStaff,
  testNotification,
  notificationHistorySelector,
  getNotificationHistory,
} from './staffSlice';
import { FormControl, InputLabel, Select, MenuItem, List, ListItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';
import { Autocomplete } from '@mui/lab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useTranslation } from 'react-i18next';
import { setCustomizedProgressLoading } from '../../slices/customizedProgressLoaderSlice';
import CustomizedProgressLoader from '../shared/CustomizedProgressLoader';
import { exportJsonDataToCSVFile } from '../../utils/fileUtil/fileSaver';
import PortalSnackBar from '../ui/PortalSnackBar';
import { portalUserSelector, setPortalSnackInfo } from '../../app/authSlice';
import PortalButton from '../shared/components/PortalButton';
import PortalIconButton from '../shared/components/PortalIconButton';
import PortalTableRow from '../shared/components/PortalTableRow';

const PREFIX = 'ImpersonateUser';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  formControl: `${PREFIX}-formControl`,
  controlPaper: `${PREFIX}-controlPaper`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardHeader: `${PREFIX}-cardHeader`,
  noRecordsRow: `${PREFIX}-noRecordsRow`,
  tableHead: `${PREFIX}-tableHead`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  selectedRow: `${PREFIX}-selectedRow`,
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, sortK) {
  if (b[sortK] < a[sortK]) {
    return -1;
  }
  if (b[sortK] > a[sortK]) {
    return 1;
  }
  return 0;
}

function getComparator(sortOrd, sortK) {
  return sortOrd === 'desc'
    ? (a, b) => descendingComparator(a, b, sortK)
    : (a, b) => -descendingComparator(a, b, sortK);
}

const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.dropdown}`]: {
    alignContent: 'center',
    width: 200,
  },

  [`& .${classes.formControl}`]: {
    width: '200px',
  },

  [`& .${classes.controlPaper}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.card}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.cardContent}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.cardHeader}`]: {
    margin: 0,
    padding: 10,
    background: '#e0e0e0',
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.noRecordsRow}`]: {
    textAlign: 'center',
  },

  [`& .${classes.tableHead}`]: {
    background: theme.palette.primary.light,
    color: 'white',
  },

  [`& .${classes.tableHeadCell}`]: {
    color: '#FFFFFF',
  },

  [`& .${classes.selectedRow}`]: {
    background: theme.palette.secondary.light,
  },
}));

export default function ImpersonateUser({ mode, selAffiliationId }) {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [lineNumber, setLineNumber] = useState('');
  const [lineAlias, setLineAlias] = useState('');
  const [accountName, setAccountName] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [type, setType] = useState('NAME');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const searchUsers = useSelector(searchUsersSelector);
  const [pwdOpen, setPwdOpen] = useState(false);
  const loading = useSelector(loadingSelector);
  const [acCAG, setAcCAG] = useState('');
  const [selWebUserId, setSelWebUserId] = useState('');
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const notificationHistoryData = useSelector(notificationHistorySelector);
  const [selectedNotificationTab, setSelectedNotificationTab] = useState(0);
  const [selectedNotificationUser, setSelectedNotificationUser] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [notificationPage, setNotificationPage] = useState(0);
  const [notificationRowsPerPage, setNotificationRowsPerPage] = useState(5);
  const [confirmPassword, setConfirmPassword] = useState('');

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const [deleteContent, setDeleteContent] = useState({});

  const [addCallerIdDialogOpen, setAddCallerIdDialogOpen] = useState(false);
  const [addChangeEmailDialogOpen, setAddChangeEmailDialogOpen] = useState(false);
  const updateUserEmailStatus = useSelector(updateUserEmailStatusSelector);
  const searchChatGroups = useSelector(chatAffiliationsSelector);
  const [passwordComplexFlag1, setPasswordComplexFlag1] = useState(false);
  const [passwordComplexFlag2, setPasswordComplexFlag2] = useState(false);
  const [passwordComplexFlag3, setPasswordComplexFlag3] = useState(false);
  const [mfaFlag, setMfaFlag] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState('email');
  const [rowsPerPage, setRowsPerPage] = useState(mode !== 'search' ? 1000 : 100);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const portalUser = useSelector(portalUserSelector);

  useEffect(() => {
    dispatch(setCustomizedProgressLoading(loading));
  }, [loading]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePwdClose = () => {
    setPwdOpen(false);
  };

  const checkPasswordStrength = (password) => {
    const n = password.length;
    let hasLower = false,
      hasUpper = false,
      hasDigit = false,
      specialChar = false;
    const specialChars = /[(!@#$%^&*)]/;

    if (password.toUpperCase() != password) hasLower = true;
    if (password.toLowerCase() != password) hasUpper = true;
    if (/[0-9]/.test(password)) hasDigit = true;
    if (specialChars.test(password)) specialChar = true;

    if (hasDigit && hasLower && hasUpper) {
      setPasswordComplexFlag1(true);
    } else {
      setPasswordComplexFlag1(false);
    }
    if (specialChar) {
      setPasswordComplexFlag2(true);
    } else {
      setPasswordComplexFlag2(false);
    }
    if (password.length > 7) {
      setPasswordComplexFlag3(true);
    } else {
      setPasswordComplexFlag3(false);
    }
  };

  const handlePasswordSave = () => {
    var user = {
      password: password,
      firstName: null,
      lastName: null,
      email: selectedUser.email,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: selectedUser.staffFlag,
      login: selectedUser.login,
    };
    var options = JSON.parse(selectedUser.optionsJson);
    user.options = JSON.stringify(options);
    user.firstName = selectedUser.firstName;
    user.lastName = selectedUser.lastName;
    user.timeZone = selectedUser.timeZone;
    dispatch(updatePortalUserPasswordAsStaff(selectedUser.id, user));
    setPwdOpen(false);
    setPassword('');
    setConfirmPassword('');
    setPasswordComplexFlag1(false);
    setPasswordComplexFlag2(false);
    setPasswordComplexFlag3(false);
  };

  const saveChangeEmail = () => {
    var user = {
      password: selectedUser.password,
      firstName: null,
      lastName: null,
      email: email,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: selectedUser.staffFlag,
      login: selectedUser.login,
    };
    var options = JSON.parse(selectedUser.optionsJson);
    user.options = JSON.stringify(options);
    user.firstName = selectedUser.firstName;
    user.lastName = selectedUser.lastName;
    user.timeZone = selectedUser.timeZone;
    dispatch(updatePortalUserEmailAsStaff(selectedUser.id, user));
    setAddChangeEmailDialogOpen(false);
    setEmail('');
  };

  const handlePasswordCancel = () => {
    setPassword('');
    setConfirmPassword('');
    setPwdOpen(false);
  };

  const handlePasswordChange = (event) => {
    switch (event.currentTarget?.name) {
      case 'password':
        checkPasswordStrength(event.target.value);
        setPassword(event.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(event.target.value);
        break;
      default:
        setPassword(event.target.value);
    }
  };

  const handleTestNotification = (userId) => {
    dispatch(getNotificationHistory(userId, 'ALL'));
    setSelectedNotificationUser(userId);
    setNotificationDialogOpen(true);
  };

  const handleOnEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTextChange = (event) => {
    switch (event.target.name) {
      case 'firstName':
        setFirstName(event.target.value);
        break;
      case 'lastName':
        setLastName(event.target.value);
        break;
      case 'accountNumber':
        setAccountNumber(event.target.value);
        break;
      case 'username':
        setUsername(event.target.value);
        break;
      case 'phoneNumber':
        setPhoneNumber(event.target.value);
        break;
      case 'lineNumber':
        setLineNumber(event.target.value);
        break;
      case 'lineAlias':
        setLineAlias(event.target.value);
        break;
      case 'accountName':
        setAccountName(event.target.value);
        break;
      case 'type':
        setType(event.target.value);
        setFirstName('');
        setLastName('');
        setAccountNumber('');
        setUsername('');
        setLineAlias('');
        setLineNumber('');
        setAccountName('');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(setSearchUsers([]));
  }, [dispatch]);

  const resetSearch = () => {
    setFirstName('');
    setLastName('');
    setAccountNumber('');
    setUsername('');
    setLineAlias('');
    setLineNumber('');
    setAccountName('');
    dispatch(setSearchUsers([]));
  };

  const handleChange = (item) => {
    console.log('');
  };

  const handleClick = (event) => {
    dispatch(
      findUsers(firstName, lastName, accountNumber, username, lineNumber, lineAlias, accountName),
    );
  };

  useEffect(() => {
    if (updateUserEmailStatus) {
      dispatch(
        findUsers(firstName, lastName, accountNumber, username, lineNumber, lineAlias, accountName),
      );
    }
  }, [updateUserEmailStatus]);

  const handleChangePassword = (event, info) => {
    setSelectedUser(info);
    setPwdOpen(true);
  };

  const handleChangeEmail = (event, info) => {
    setSelectedUser(info);
    setAddChangeEmailDialogOpen(true);
  };

  const handleDelete = (event, info) => {
    setDeleteDialogOpen(true);
    setDeleteContent(info);
  };

  const handledeleteDialogConfirm = () => {
    var user = {};
    setSelectedUser(deleteContent);
    dispatch(deletePortalUserAsStaff(deleteContent.id, user));
    setDeleteDialogOpen(false);
  };

  const handledeleteDialogCancel = (event, info) => {
    setDeleteDialogOpen(false);
  };

  const handleAddDialogCancel = (event, info) => {
    setAddDialogOpen(false);
  };

  const handleAuth0Push = (event, info) => {
    var user = {
      password: password,
      firstName: null,
      lastName: null,
      email: null,
      options: null,
      windowsLogin: null,
      erpLogin: null,
      staffFlag: null,
      login: info.login,
    };
    setSelectedUser(info);
    dispatch(createAuth0UserAsStaff(info.id, user));
  };

  const handleRowClick = (event, login, info) => {
    setSelectedUser(info);
    localStorage.setItem('lastSelAccount', '');
    localStorage.setItem('lastSelReceiver', '');
    localStorage.setItem('lastSelReceiverName', '');
    window.open(`https://${window.location.hostname}/home?xauth=${login}`, '_blank');
  };

  const handleAddCAG = (affiliationId, webuserid) => {
    if (selAffiliationId) {
      var affliations = searchChatGroups
        .slice()
        .filter((element) => element.id === selAffiliationId);
      if (affliations && affliations.length === 1) {
        setAcCAG(affliations[0]);
      }
    } else {
      dispatch(getAvaialbleAffiliations());
    }
    setSelWebUserId(webuserid);
    setAddDialogOpen(true);
  };

  const handleAddCallerId = (event, webuserid) => {
    setPhoneNumber('');
    setSelWebUserId(webuserid);
    setAddCallerIdDialogOpen(true);
  };

  const handleChangeMfa = (value, seluser) => {
    var user = { mfa: value };
    dispatch(updatePortalUserMfaAsStaff(seluser.id, user));
    setMfaFlag(value);
  };

  const handleResetMfa = (value, seluser) => {
    var user = { mfa: value };
    dispatch(resetPortalUserMfaAsStaff(seluser.id, user));
    setMfaFlag(false);
  };

  const saveAddCallerId = () => {
    dispatch(addCallerIdToWebUser(selWebUserId.id, phoneNumber));
    setAddCallerIdDialogOpen(false);
  };

  const handleAddCallerIdCancel = (event, webuserid) => {
    setAddCallerIdDialogOpen(false);
  };

  const handleChangeEmailCancel = (event, webuserid) => {
    setAddChangeEmailDialogOpen(false);
  };

  const addUserToCAG = (affiliationId) => {
    dispatch(addChatUserToGroup(affiliationId.id, selWebUserId.id));
    setAddDialogOpen(false);
  };

  const createSortHandler = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
    }
    setSortKey(key);
  };

  const handleDownloads = () => {
    try {
      const data = searchUsers.map((user) => {
        return {
          Email: user.email,
          'Username/Login': user.email,
          'First Name': user.firstName,
          'Last Name': user.lastName,
        };
      });
      exportJsonDataToCSVFile({ jsonData: data, filename: 'Users' });
    } catch {
      dispatch(
        setPortalSnackInfo({
          severity: 'error',
          message: 'Oops, something went wrong while downloading file. Please contact IT.',
        }),
      );
    }
  };

  return (
    <StyledRoot className={classes.root}>
      <CustomizedProgressLoader color="secondary" />
      <PortalSnackBar />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.controlPaper}>
            <FormControl className={classes.formControl} variant="standard">
              <InputLabel id="demo-simple-select-label">Search By</InputLabel>
              <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                name="type"
                onChange={handleTextChange}
                value={type}
              >
                <MenuItem value="NAME">Name</MenuItem>
                <MenuItem value="ACCOUNT">Account Number</MenuItem>
                <MenuItem value="USERNAME">Username</MenuItem>
                <MenuItem value="LINENUMBER">Line Number</MenuItem>
                <MenuItem value="LINEALIAS">Line Alias</MenuItem>
                <MenuItem value="ACCOUNTNAME">Account Name</MenuItem>
              </Select>
            </FormControl>

            {type === 'NAME' && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                id="standard-helperText1"
                label="First Name"
                name="firstName"
                onChange={handleTextChange}
                value={firstName}
                variant="standard"
              />
            )}

            {type === 'NAME' && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                id="standard-helperText3"
                label="Last Name"
                name="lastName"
                onChange={handleTextChange}
                value={lastName}
                variant="standard"
              />
            )}

            {type === 'ACCOUNT' && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                id="standard-helperText4"
                label="Account Number"
                name="accountNumber"
                onChange={handleTextChange}
                value={accountNumber}
                variant="standard"
              />
            )}

            {type === 'USERNAME' && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                id="standard-helperText5"
                label="Username"
                name="username"
                onChange={handleTextChange}
                value={username}
                variant="standard"
              />
            )}

            {type === 'LINENUMBER' && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                id="standard-helperText5"
                label="Line Number"
                name="lineNumber"
                onChange={handleTextChange}
                value={lineNumber}
                variant="standard"
              />
            )}

            {type === 'LINEALIAS' && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                id="standard-helperText5"
                label="Line Alias"
                name="lineAlias"
                onChange={handleTextChange}
                value={lineAlias}
                variant="standard"
              />
            )}

            {type === 'ACCOUNTNAME' && (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                id="standard-helperText5"
                label="Account Name"
                name="accountName"
                onChange={handleTextChange}
                value={accountName}
                variant="standard"
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <PortalButton color="primary" onClick={handleClick} variant="contained">
                Search
              </PortalButton>
              <PortalButton color="secondary" onClick={resetSearch} variant="contained">
                Reset
              </PortalButton>
              {searchUsers && searchUsers?.length > 0 && (
                <PortalButton
                  component="span"
                  data-tour="rtDownloadMsg"
                  onClick={handleDownloads}
                  startIcon={<GetAppIcon />}
                  variant="contained"
                >
                  {t('Download')}
                </PortalButton>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {searchUsers && searchUsers.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="dense table" className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell className={classes.tableHeadCell} sx={{ pl: 4 }}>
                      <TableSortLabel
                        active={sortKey === 'email'}
                        classes={{
                          active: classes.active,
                          icon: classes.icon,
                          root: classes.sortCell,
                        }}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler('email')}
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      <TableSortLabel
                        active={sortKey === 'login'}
                        classes={{
                          active: classes.active,
                          icon: classes.icon,
                          root: classes.sortCell,
                        }}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler('login')}
                      >
                        Username/Login
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      <TableSortLabel
                        active={sortKey === 'firstName'}
                        classes={{
                          active: classes.active,
                          icon: classes.icon,
                          root: classes.sortCell,
                        }}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler('firstName')}
                      >
                        First Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      <TableSortLabel
                        active={sortKey === 'lastName'}
                        classes={{
                          active: classes.active,
                          icon: classes.icon,
                          root: classes.sortCell,
                        }}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler('lastName')}
                      >
                        Last name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      <TableSortLabel
                        active={sortKey === 'staffFlag'}
                        classes={{
                          active: classes.active,
                          icon: classes.icon,
                          root: classes.sortCell,
                        }}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler('staffFlag')}
                      >
                        Staff
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      <TableSortLabel
                        active={sortKey === 'auth0UserEntity'}
                        classes={{
                          active: classes.active,
                          icon: classes.icon,
                          root: classes.sortCell,
                        }}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler('auth0')}
                      >
                        Auth0
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell}>
                      <TableSortLabel
                        active={sortKey === 'mfa'}
                        classes={{
                          active: classes.active,
                          icon: classes.icon,
                          root: classes.sortCell,
                        }}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler('mfa')}
                      >
                        MFA
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      <TableSortLabel
                        active={sortKey === 'chatGroup'}
                        classes={{
                          active: classes.active,
                          icon: classes.icon,
                          root: classes.sortCell,
                        }}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                        onClick={() => createSortHandler('chatGroup')}
                      >
                        SCAG
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeadCell} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchUsers &&
                    searchUsers.length > 0 &&
                    searchUsers
                      .slice()
                      .sort((a, b) =>
                        a[sortKey] && b[sortKey] && a[sortKey] !== null && b[sortKey] !== null
                          ? sortOrder === 'asc'
                            ? a[sortKey].localeCompare(b[sortKey])
                            : b[sortKey].localeCompare(a[sortKey])
                          : sortOrder === 'asc'
                            ? a[sortKey] === null
                              ? 1
                              : -1
                            : a[sortKey] === null
                              ? -1
                              : 1,
                      )
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <PortalTableRow
                            hover
                            key={row.number}
                            sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(0.999)',
                              },
                            }}
                          >
                            <TableCell component="th" scope="row" sx={{ pl: 4 }}>
                              {row.email}
                              {row.idpuser ? (
                                <Chip
                                  label="SSO user"
                                  style={{
                                    marginLeft: 10,
                                    backgroundColor: 'green',
                                    color: 'white',
                                  }}
                                  variant="outlined"
                                />
                              ) : null}
                            </TableCell>
                            <TableCell align="center">{row.login}</TableCell>
                            <TableCell align="center">{row.firstName}</TableCell>
                            <TableCell align="center">{row.lastName}</TableCell>
                            <TableCell align="center">{row.staffFlag}</TableCell>
                            <TableCell align="center">
                              {row.auth0UserEntity && row.auth0UserEntity.id
                                ? 'Synced'
                                : 'Not Synced'}
                            </TableCell>
                            <TableCell align="center">
                              {row.auth0UserEntity && row.auth0UserEntity.mfa === 'true'
                                ? 'Enabled'
                                : 'Disabled'}
                            </TableCell>
                            <TableCell>
                              {row.affiliationName ? row.affiliationName : 'N/A'}
                            </TableCell>
                            <TableCell align="center">
                              {!mode && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {row && !row.idpuser && (
                                    <PortalButton
                                      disabled={row.auth0UserEntity}
                                      onClick={(event) => handleAuth0Push(event, row)}
                                      size="small"
                                      variant="outlined"
                                    >
                                      Sync
                                    </PortalButton>
                                  )}
                                  {row && !row.idpuser && (
                                    <PortalButton
                                      disabled={!row.auth0UserEntity}
                                      onClick={(event) => handleRowClick(event, row.login, row)}
                                      size="small"
                                      variant="outlined"
                                    >
                                      Impersonate (View Only)
                                    </PortalButton>
                                  )}
                                  {row && !row.idpuser && (
                                    <PortalButton
                                      disabled={!row.auth0UserEntity}
                                      onClick={(event) => handleChangePassword(event, row)}
                                      size="small"
                                      variant="outlined"
                                    >
                                      Change Password
                                    </PortalButton>
                                  )}
                                  {row && !row.idpuser && (
                                    <PortalButton
                                      disabled={!row.auth0UserEntity}
                                      onClick={(event) => handleChangeEmail(event, row)}
                                      size="small"
                                      variant="outlined"
                                    >
                                      Change Email
                                    </PortalButton>
                                  )}
                                  <PortalButton
                                    disabled={!row.auth0UserEntity}
                                    onClick={(event) => handleDelete(event, row)}
                                    size="small"
                                    variant="outlined"
                                  >
                                    Delete
                                  </PortalButton>
                                  <PortalButton
                                    disabled={
                                      !row.auth0UserEntity ||
                                      (row.affiliationContacts && row.affiliationContacts != null)
                                    }
                                    onClick={(event) => handleAddCAG(event, row)}
                                    size="small"
                                    variant="outlined"
                                  >
                                    Add Secure Chat Access Group
                                  </PortalButton>
                                  {row && !row.idpuser && (
                                    <PortalButton
                                      onClick={(event) =>
                                        handleChangeMfa(
                                          !(
                                            row.auth0UserEntity &&
                                            row.auth0UserEntity.mfa === 'true'
                                          ),
                                          row,
                                        )
                                      }
                                      size="small"
                                      variant="outlined"
                                    >
                                      {row.auth0UserEntity && row.auth0UserEntity.mfa === 'true'
                                        ? 'Disable MFA'
                                        : 'Enable MFA'}
                                    </PortalButton>
                                  )}
                                  {row &&
                                    !row.idpuser &&
                                    row.auth0UserEntity &&
                                    row.auth0UserEntity.mfa &&
                                    row.auth0UserEntity.mfa === 'true' && (
                                      <PortalButton
                                        onClick={(event) => handleResetMfa(false, row)}
                                        size="small"
                                        variant="outlined"
                                      >
                                        Reset MFA
                                      </PortalButton>
                                    )}
                                  {portalUser &&
                                    (portalUser.login === 'SALLURI' ||
                                      portalUser.login === 'TESTCASE105' ||
                                      portalUser.login === 'RJUSTIS' ||
                                      portalUser.login === 'SSARNAVSKIY') && (
                                      <PortalButton
                                        onClick={(event) => handleTestNotification(row.id)}
                                        size="small"
                                        variant="outlined"
                                      >
                                        Notification
                                      </PortalButton>
                                    )}
                                </Box>
                              )}
                              {mode && mode === 'search' && (
                                <PortalButton
                                  disabled={
                                    !row.auth0UserEntity ||
                                    (row.affiliationContacts && row.affiliationContacts != null)
                                  }
                                  onClick={(event) => handleAddCAG(event, row)}
                                  size="small"
                                  variant="outlined"
                                >
                                  Add Secure Chat Access Group
                                </PortalButton>
                              )}
                            </TableCell>
                          </PortalTableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {searchUsers &&
            searchUsers.length === 0 &&
            (accountNumber.length > 0 ||
              firstName.length > 0 ||
              lastName.length > 0 ||
              username.length > 0 ||
              lineNumber.length > 0 ||
              lineAlias.length > 0 ||
              accountName.length > 0) && (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Typography>No Users Found</Typography>
              </div>
            )}

          <Dialog
            aria-labelledby="notification-dialog-title"
            fullWidth={true}
            maxWidth="false"
            onClose={() => {
              setNotificationDialogOpen(false);
            }}
            open={notificationDialogOpen}
          >
            <DialogTitle id="notification-dialog-title">Notification Logs</DialogTitle>
            <DialogContent>
              <Tabs
                aria-label="notification tabs"
                onChange={(event, newValue) => {
                  setSelectedNotificationTab(newValue);
                  switch (newValue) {
                    case 0:
                      dispatch(getNotificationHistory(selectedNotificationUser, 'ALL'));
                      break;
                    case 1:
                      dispatch(getNotificationHistory(selectedNotificationUser, 'MESSAGE'));
                      break;
                    case 2:
                      dispatch(getNotificationHistory(selectedNotificationUser, 'CHAT'));
                      break;
                    case 3:
                      dispatch(getNotificationHistory(selectedNotificationUser, 'TEXT'));
                      break;
                    case 4:
                      dispatch(getNotificationHistory(selectedNotificationUser, 'TEST'));
                      break;
                    default:
                      break;
                  }
                }}
                value={selectedNotificationTab}
              >
                <Tab label="All" />
                <Tab label="Secure Message" />
                <Tab label="Chat" />
                <Tab label="Text" />
                <Tab label="Test" />
              </Tabs>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Log</TableCell>
                      <TableCell>Device Type</TableCell>
                      <TableCell>Device Name</TableCell>
                      <TableCell>Sent Time</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Notification Type</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Body</TableCell>
                      <TableCell>From Number</TableCell>
                      <TableCell>To Number</TableCell>
                      <TableCell>App Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {console.log(
                      'notificationHistoryData',
                      stableSort(notificationHistoryData, getComparator(sortOrder, sortKey)).slice(
                        notificationPage * notificationRowsPerPage,
                        notificationPage * notificationRowsPerPage + notificationRowsPerPage,
                      ),
                    )}
                    {notificationHistoryData &&
                      notificationHistoryData.length > 0 &&
                      stableSort(notificationHistoryData, getComparator(sortOrder, sortKey))
                        .slice(
                          notificationPage * notificationRowsPerPage,
                          notificationPage * notificationRowsPerPage + notificationRowsPerPage,
                        )
                        .map((row, index) => {
                          return (
                            <TableRow key={row.id}>
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.log}</TableCell>
                              <TableCell>{row?.fcmToken?.type}</TableCell>
                              <TableCell>{row?.fcmToken?.device}</TableCell>
                              <TableCell>{new Date(row.sentTime).toLocaleString()}</TableCell>
                              <TableCell>{row.status}</TableCell>
                              <TableCell>{row.notificationType}</TableCell>
                              <TableCell>{row.title}</TableCell>
                              <TableCell>{row.body}</TableCell>
                              <TableCell>{row.fromNumber}</TableCell>
                              <TableCell>{row.toNumber}</TableCell>
                              <TableCell>{row?.fcmToken?.appName}</TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={notificationHistoryData.length}
                onPageChange={(event, newPage) => {
                  console.log('onPageChange', newPage);
                  setNotificationPage(newPage);
                }}
                onRowsPerPageChange={(event, newPage) => {
                  console.log('onRowsPerPageChange', newPage);
                  console.log('event', event);
                  setNotificationRowsPerPage(parseInt(event.target.value, 10));
                  setNotificationPage(0);
                }}
                page={notificationPage}
                rowsPerPage={notificationRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
              <PortalButton
                onClick={() => {
                  dispatch(testNotification(selectedNotificationUser));
                  dispatch(
                    getNotificationHistory(
                      selectedNotificationUser,
                      selectedNotificationTab === 0
                        ? 'ALL'
                        : selectedNotificationTab === 1
                          ? 'MESSAGE'
                          : selectedNotificationTab === 2
                            ? 'CHAT'
                            : selectedNotificationTab === 3
                              ? 'TEXT'
                              : 'TEST ',
                    ),
                  );
                }}
                variant="contained"
              >
                SEND TEST NOTIFICATION
              </PortalButton>
            </DialogContent>
          </Dialog>

          <Dialog
            aria-labelledby="form-dialog-title"
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="sm"
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handlePwdClose();
              }
            }}
            open={pwdOpen}
          >
            <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
            <DialogContent>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemIcon>
                    {passwordComplexFlag1 ? (
                      <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    ) : (
                      <HighlightOffIcon style={{ color: 'red' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Minimum of one lower case (a-z), one upper case (A-Z) and one number (0-9)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {passwordComplexFlag2 ? (
                      <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    ) : (
                      <HighlightOffIcon style={{ color: 'red' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Minimum of one special character (!@#$%^&*)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {passwordComplexFlag3 ? (
                      <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    ) : (
                      <HighlightOffIcon style={{ color: 'red' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Must have 8 characters in length (Max length supported is 50 characters)" />
                </ListItem>
              </List>
              <DialogContentText>
                <Typography style={{ color: 'red' }}>
                  {password !== confirmPassword
                    ? 'New password & confirm password should match.'
                    : ''}
                </Typography>
                <Typography style={{ color: 'red' }}>
                  {password && password.length < 8
                    ? 'Passwords should be a minimum of 8 characters.'
                    : ''}
                </Typography>
              </DialogContentText>
              <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
              <Input
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                endAdornment={
                  <InputAdornment position="end">
                    <PortalIconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </PortalIconButton>
                  </InputAdornment>
                }
                fullWidth
                inputProps={{ maxLength: 50 }}
                label="New Password"
                name="password"
                onChange={handlePasswordChange}
                type={showPassword ? 'text' : 'password'}
                value={password}
                variant="outlined"
              />
              <InputLabel htmlFor="standard-adornment-password" style={{ marginTop: '10px' }}>
                Confirm Password
              </InputLabel>
              <Input
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                endAdornment={
                  <InputAdornment position="end">
                    <PortalIconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </PortalIconButton>
                  </InputAdornment>
                }
                fullWidth
                inputProps={{ maxLength: 50 }}
                label="Confirm Password"
                name="confirmPassword"
                onChange={handlePasswordChange}
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <PortalButton
                className={classes.btn}
                color="primary"
                disabled={
                  password === '' ||
                  confirmPassword === '' ||
                  confirmPassword !== password ||
                  !passwordComplexFlag1 ||
                  !passwordComplexFlag2 ||
                  !passwordComplexFlag3
                }
                onClick={handlePasswordSave}
                variant="outlined"
              >
                Save
              </PortalButton>
              <PortalButton
                className={classes.btn}
                color="secondary"
                onClick={handlePasswordCancel}
                variant="outlined"
              >
                Close
              </PortalButton>
            </DialogActions>
          </Dialog>

          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handledeleteDialogCancel}
            open={deleteDialogOpen}
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to delete user ?
            </DialogTitle>
            <DialogActions>
              <PortalButton
                autoFocus
                color="primary"
                onClick={handledeleteDialogConfirm}
                variant="contained"
              >
                Delete
              </PortalButton>
              <PortalButton
                color="secondary"
                onClick={handledeleteDialogCancel}
                variant="contained"
              >
                Cancel
              </PortalButton>
            </DialogActions>
          </Dialog>

          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handleAddCallerIdCancel}
            open={addCallerIdDialogOpen}
          >
            <DialogTitle id="alert-dialog-title">Add Verified Callerid</DialogTitle>
            <TextField
              fullWidth
              inputProps={{ maxLength: 12 }}
              label="Phone Number"
              name="phoneNumber"
              onChange={handleTextChange}
              required
              type="text"
              value={phoneNumber}
              variant="standard"
            />
            <DialogActions>
              <PortalButton color="primary" onClick={saveAddCallerId} variant="contained">
                Add
              </PortalButton>
              <PortalButton
                autoFocus
                color="secondary"
                onClick={handleAddCallerIdCancel}
                variant="contained"
              >
                Cancel
              </PortalButton>
            </DialogActions>
          </Dialog>

          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            onClose={handleChangeEmailCancel}
            open={addChangeEmailDialogOpen}
          >
            <DialogTitle id="alert-dialog-title">Change Email</DialogTitle>
            <TextField
              fullWidth
              label="Email"
              name="email"
              onChange={handleOnEmailChange}
              required
              type="text"
              value={email}
              variant="outlined"
            />
            <DialogActions>
              <PortalButton
                color="primary"
                disabled={
                  !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    email,
                  )
                }
                onClick={saveChangeEmail}
                variant="contained"
              >
                Update
              </PortalButton>
              <PortalButton
                autoFocus
                color="secondary"
                onClick={handleChangeEmailCancel}
                variant="contained"
              >
                Cancel
              </PortalButton>
            </DialogActions>
          </Dialog>

          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handleAddDialogCancel}
            open={addDialogOpen}
          >
            <DialogTitle id="alert-dialog-title">Add User to Secure Chat Access Group</DialogTitle>
            {searchChatGroups && searchChatGroups.length > 0 && (
              <Autocomplete
                data-tour="rtSelRecv"
                fullWidth
                getOptionLabel={(option) => option?.name || ''}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  handleChange(newValue);
                  setAcCAG(newValue);
                }}
                options={
                  searchChatGroups
                    ? searchChatGroups
                        .slice()
                        .sort((a, b) =>
                          a.name
                            ? a.name.localeCompare(b.name ? b.name : '')
                            : ''.localeCompare(b.name ? b.name : ''),
                        )
                    : []
                }
                renderInput={(params) => {
                  if (params) {
                    return (
                      <TextField {...params} label="Select Chat Access Group" variant="outlined" />
                    );
                  }
                  return null;
                }}
                style={{ flex: 1, marginRight: '8px', background: 'white' }}
                value={acCAG}
              />
            )}
            <DialogActions>
              <PortalButton color="primary" onClick={() => addUserToCAG(acCAG)} variant="contained">
                Add
              </PortalButton>
              <PortalButton
                autoFocus
                color="secondary"
                onClick={handleAddDialogCancel}
                variant="contained"
              >
                Cancel
              </PortalButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </StyledRoot>
  );
}
