import red from '@mui/material/colors/red';
import teal from '@mui/material/colors/teal';
import pink from '@mui/material/colors/pink';
import * as colors from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { isLocalOrDevEnvironment } from './utils/urls';

const getStyleByBrand = (weight, themeValue) => {
  var value = themeValue ? themeValue : 'localhost';
  switch (value) {
    case 'localhost':
      return colors.deepPurple[weight];
    case 'pink':
      return pink[weight];
    case 'red':
      return red[weight];
    case 'teal':
      return teal[weight];
    case 'blue':
      return colors.cyan[weight];
    case 'bluegrey':
      return colors.blueGrey[weight];
    case 'dark':
      return '#000000';
    default:
      return colors.cyan[weight];
  }
};

const getSecondaryByBrand = (weight, themeValue) => {
  var value = themeValue ? themeValue : 'localhost';
  switch (value) {
    case 'localhost':
      return colors.lightBlue[weight];
    case 'pink':
      return colors.amber[weight];
    case 'red':
      return '#ef5350';
    case 'teal':
      return colors.amber[weight];
    case 'blue':
      return colors.orange[weight];
    case 'bluegrey':
      return colors.orange[weight];
    case 'dark':
      return colors.deepOrange[weight];
    default:
      return colors.deepOrange[weight];
  }
};

const getTableHeaderColor = (themeValue) => {
  var value = themeValue ? themeValue : 'localhost';
  switch (value) {
    case 'dark':
      return 'white';
    default:
      return 'inherit';
  }
};

const getThemeBackground = () => {
  if (isLocalOrDevEnvironment()) {
    return '../ExtendCommunicationsBackground.jpg';
  } else if (window.location.host === 'secure2.extendcomm.com') {
    return '../ExtendCommunicationsBackground.jpg';
  } else if (window.location.host === 'secure2.passwordinc.com') {
    return '../PasswordBackground.png';
  } else if (window.location.host === 'secure2.ccianswers.com') {
    return '../CentralCommunicationsIncBackground.jpg';
  } else if (window.location.host === 'portal.callcenters.com') {
    return '../CommonLandingPage.jpg';
  } else if (window.location.host === 'secure2.commcenterpa.com') {
    return '../CommCenterBackground.png';
  } else if (window.location.host === 'secure2.theperfectanswer.com') {
    return '../PerfectAnswerBackground.png';
  } else if (window.location.host === 'secure2.thedoctorsanswer.com') {
    return '../TheDoctorsAnswerBackground.jpg';
  } else if (window.location.host === 'secure2.courteouscom.com') {
    return '../CourteousBackground.jpg';
  } else if (window.location.host === 'secure2.answermti.com') {
    return '../AnswerMTIBackground.jpeg';
  } else if (window.location.host === 'secure2.byrnesmessage.com') {
    return '../ByrnesBackground.jpg';
  } else if (window.location.host === 'secure2.centracomm.com') {
    return '../CentraCommBackground.jpg';
  } else if (window.location.host === 'secure2.answerinc.net') {
    return '../AnswerIncBackground.jpg';
  } else if (window.location.host === 'secure2.answercalifornia.com') {
    return '../AnswerCaliforniaBackground.jpg';
  } else if (window.location.host === 'secure2.voice-one.com') {
    return '../VoiceOneBackground.jpg';
  } else if (window.location.host === 'secure2.anserfone.net') {
    return '../AnserfoneBackground.png';
  } else if (window.location.host === 'secure2.answeringmemphis.com') {
    return '../AnsweringMemphisBackground.jpg';
  } else if (window.location.host === 'secure2.livemessageamerica.com') {
    return '../LiveMessageAmericaBackground.png';
  } else if (window.location.host === 'secure2.telassist.com') {
    return '../TelAssistBackgroundUpdated.jpg';
  } else if (window.location.host === 'secure2.answercenter.biz') {
    return '../AnswerCenterBackground.jpg';
  } else if (window.location.host === 'secure2.sound-tele.com') {
    return '../SoundTelecomBackground.jpg';
  } else if (window.location.host === 'secure2.centralcomm.com') {
    return '../CentralCommBackground.jpg';
  } else if (window.location.host === 'secure2.livevoice.com') {
    return '../LiveVoiceBackground.jpg';
  } else if (window.location.host === 'secure2.tigertel.com') {
    return '../TigertelBackground.jpg';
  } else if (window.location.host === 'secure2.take-calls.com') {
    return '../AnswerPhoneBackground.png';
  } else if (window.location.host === 'secure2.appletreeanswers.com') {
    return '../AppletreeBackground.jpg';
  } else if (window.location.host === 'secure2.cathedralans.com') {
    return '../CathedralBackground.jpeg';
  } else if (window.location.host === 'secure2.medproservice.net') {
    return '../MedProAnsweringBackground.png';
  } else if (window.location.host === 'secure2.portcity.com') {
    return '../PortCityBackgroundUpdated.png';
  } else if (window.location.host === 'secure2.clementineanswers.com') {
    return '../clementineanswersBackgroundUpdated.jpg';
  } else if (window.location.host === 'secure2.wegetthemessage.com') {
    return '../ProCommBackgroundUpdated.jpg';
  } else if (window.location.host === 'secure2.advantage-plus.com') {
    return '../AdvantageAnsweringBackground.png';
  } else if (window.location.host === 'secure2.townlinetas.com') {
    return '../TownLineBackground.png';
  } else if (window.location.host === 'secure2.parkwaymessage.com') {
    return '../ParkwayMessageBackground.png';
  } else if (window.location.host === 'secure2.executel.biz') {
    return '../ExecutelBackgroundUpdated.jpg';
  } else if (window.location.host === 'secure2.viponcall.com') {
    return '../VIPConnectionsBackground.png';
  } else if (window.location.host === 'secure2.metromedicalanswering.com') {
    return '../MetroMedicalBackground.jpg';
  } else if (window.location.host === 'secure2.answerone.us') {
    return '../AnswerOneBackground.jpg';
  } else if (window.location.host === 'secure2.aplv.com') {
    return '../AnswerPlusLasVegasBackground.jpg';
  } else if (window.location.host === 'secure2.answernetworkllc.com') {
    return '../AnswerNetworkBackground.jpg';
  } else if (window.location.host === 'secure2.flatrateansweringservice.com') {
    return '../FlatrateansweringBackgroundUpdated.jpg';
  } else if (window.location.host === 'secure2.signius.com') {
    return '../SigniusBackground.jpg';
  } else if (window.location.host === 'secure2.notifymd.com') {
    return '../NotifyMDBackground.jpg';
  } else if (window.location.host === 'secure2.gwhsllc.com') {
    return '../GWHSBackground.jpg';
  } else if (window.location.host === 'secure2.dchoice.com') {
    return '../DirectorsChoiceBackground.png';
  } else if (window.location.host === 'secure2.acena.com') {
    return '../Acena.jpg';
  } else if (window.location.host === 'secure2.wagnercommunications.net') {
    return '../WagnerCommunicationsBackground.png';
  } else if (window.location.host === 'secure2.cmscom.net') {
    return '../CmscomBackground.jpg';
  } else if (window.location.host === 'secure2.bcanswer.com') {
    return '../businessconnectionsbackground.png';
  } else if (window.location.host === 'secure2.mapcommunications.com') {
    return 'https://www.mapcommunications.com/wp-content/uploads/2015/09/banner.jpg';
  } else {
    return 'https://www.mapcommunications.com/wp-content/uploads/2015/09/banner.jpg';
  }
};

const getDefaultLogoPath = () => {
  if (isLocalOrDevEnvironment()) {
    return '../ExtendCommunicationsUpdated.svg';
  } else if (window.location.host === 'secure2.extendcomm.com') {
    return '../ExtendCommunicationsUpdated.svg';
  } else if (window.location.host === 'secure2.passwordinc.com') {
    return '../Password.png';
  } else if (window.location.host === 'secure2.ccianswers.com') {
    return '../CentralCommunications.png';
  } else if (window.location.host === 'secure2.cmscom.net') {
    return '../Cmscom.svg';
  } else if (window.location.host === 'secure2.gwhsllc.com') {
    return '../GWHSLogo.png';
  } else if (window.location.host === 'portal.callcenters.com') {
    return '';
  } else if (window.location.host === 'secure2.commcenterpa.com') {
    return '../CommCenterLogo.png';
  } else if (window.location.host === 'secure2.theperfectanswer.com') {
    return '../PerfectAnswerLogo.jpg';
  } else if (window.location.host === 'secure2.thedoctorsanswer.com') {
    return '../TheDoctorsAnswerLogo.jpg';
  } else if (window.location.host === 'secure2.courteouscom.com') {
    return '../Courteous.png';
  } else if (window.location.host === 'secure2.centracomm.com') {
    return '../CentraComm.jpg';
  } else if (window.location.host === 'secure2.byrnesmessage.com') {
    return '../ByrnesMessageUpdated.png';
  } else if (window.location.host === 'secure2.answerinc.net') {
    return '../AnswerIncUpdated.png';
  } else if (window.location.host === 'secure2.answercalifornia.com') {
    return '../AnswerCalifornia.png';
  } else if (window.location.host === 'secure2.voice-one.com') {
    return '../VoiceOne.jpg';
  } else if (window.location.host === 'secure2.anserfone.net') {
    return '../Anserfone.jpg';
  } else if (window.location.host === 'secure2.answeringmemphis.com') {
    return '../AnsweringMemphis.jpg';
  } else if (window.location.host === 'secure2.livemessageamerica.com') {
    return '../LiveMessageAmericaUpdated.png';
  } else if (window.location.host === 'secure2.telassist.com') {
    return '../TelAssist.png';
  } else if (window.location.host === 'secure2.answercenter.biz') {
    return '../AnswerCenter.png';
  } else if (window.location.host === 'secure2.sound-tele.com') {
    return '../SoundTele.jpg';
  } else if (window.location.host === 'secure2.answermti.com') {
    return '../AnswerMTI.png';
  } else if (window.location.host === 'secure2.centralcomm.com') {
    return '../CentralComm.png';
  } else if (window.location.host === 'secure2.livevoice.com') {
    return '../LiveVoice.png';
  } else if (window.location.host === 'secure2.tigertel.com') {
    return '../Tigertel.png';
  } else if (window.location.host === 'secure2.take-calls.com') {
    return '../AnswerPhone.png';
  } else if (window.location.host === 'secure2.acena.com') {
    return '../AcenaLogo.png';
  } else if (window.location.host === 'secure2.wagnercommunications.net') {
    return '../WagnerLogo.png';
  } else if (window.location.host === 'secure2.bcanswer.com') {
    return '../businessconnections.png';
  } else if (window.location.host === 'secure2.appletreeanswers.com') {
    return '../AppleTreeAnswersLogo.png';
  } else if (window.location.host === 'secure2.prospeakny.com') {
    return '../ProspeaknyLogo.png';
  } else if (window.location.host === 'secure2.cathedralans.com') {
    return '../Cathedral.gif';
  } else if (window.location.host === 'secure2.medproservice.net') {
    return '../MedProAnswering.png';
  } else if (window.location.host === 'secure2.portcity.com') {
    return '../PortCity.webp';
  } else if (window.location.host === 'secure2.clementineanswers.com') {
    return '../Clementine.png';
  } else if (window.location.host === 'secure2.wegetthemessage.com') {
    return '../ProComm.png';
  } else if (window.location.host === 'secure2.advantage-plus.com') {
    return '../AdvantageAnswering.png';
  } else if (window.location.host === 'secure2.townlinetas.com') {
    return '../TownLine.png';
  } else if (window.location.host === 'secure2.parkwaymessage.com') {
    return '../ParkwayMessage.jpg';
  } else if (window.location.host === 'secure2.executel.biz') {
    return '../Executel.jpg';
  } else if (window.location.host === 'secure2.viponcall.com') {
    return '../VIPConnections.png';
  } else if (window.location.host === 'secure2.metromedicalanswering.com') {
    return '../MetroMedical.jpg';
  } else if (window.location.host === 'secure2.answerone.us') {
    return '../AnswerOne.jpg';
  } else if (window.location.host === 'secure2.aplv.com') {
    return '../AnswerPlusLasVegas.png';
  } else if (window.location.host === 'secure2.answernetworkllc.com') {
    return '../AnswerNetwork.png';
  } else if (window.location.host === 'secure2.flatrateansweringservice.com') {
    return '../FlatRate.jpg';
  } else if (window.location.host === 'secure2.signius.com') {
    return '../Signius.png';
  } else if (window.location.host === 'secure2.notifymd.com') {
    return '../notifymdlogo.svg';
  } else if (window.location.host === 'secure2.dchoice.com') {
    return '../DirectorsChoice.png';
  } else if (window.location.host === 'secure2.mapcommunications.com') {
    return '../Web-2023-MapLogo-RGB-color.png';
  } else {
    return '../Web-2023-MapLogo-RGB-color.png';
  }
};

export const theme = createTheme({
  palette: {
    primary: {
      main: getStyleByBrand(700, 'blue'),
      light: getStyleByBrand(300, 'blue'),
    },
    secondary: {
      main: getSecondaryByBrand(300, 'blue'),
      light: getSecondaryByBrand(50, 'blue'),
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'inherit',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
  },
  portalDefaults: {
    background: `url(${getThemeBackground()})`,
    logo: `url(${getDefaultLogoPath()})`,
  },
});

export default function getTheme(themeValue) {
  const tableHeaderColor = getTableHeaderColor(themeValue);
  const createdTheme = createTheme({
    palette: {
      primary: {
        main: getStyleByBrand(700, themeValue),
        light: getStyleByBrand(300, themeValue),
      },
      secondary: {
        main: getSecondaryByBrand(300, themeValue),
        light: getSecondaryByBrand(50, themeValue),
      },
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '16px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation0: {
            boxShadow: `0 2px 2px 1px ${getStyleByBrand(300, themeValue)}82`,
            borderTop: '1px inset #4dd0e182',
          },
          elevation1: {
            boxShadow: `0 2px 2px 1px ${getStyleByBrand(300, themeValue)}82`,
            borderTop: '1px inset #4dd0e182',
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: tableHeaderColor,
            '&:hover': {
              color: tableHeaderColor,
            },
            '&.Mui-active': {
              color: `${tableHeaderColor} !important`,
              fontWeight: 'bold',
              '&:hover': {
                color: tableHeaderColor,
              },
            },
          },
          icon: {
            color: `${tableHeaderColor} !important`,
            '&:hover': {
              color: tableHeaderColor,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: tableHeaderColor,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTabs-indicator': {
              height: '3.5px',
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: 'inherit',
            },
          },
        },
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 16,
    },
    portalDefaults: {
      background: `url(${getThemeBackground()})`,
      logo: `url(${getDefaultLogoPath()})`,
    },
  });
  return createdTheme;
}
