import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from 'date-fns';

export const getDefaultDateRangeObject = (startDate, endDate) => {
  return {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };
};

const dateRangeStaticRanges = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

export const defaultStaticRangesOption = {
  today: {
    label: 'Today',
    range: () => ({
      startDate: dateRangeStaticRanges.startOfToday,
      endDate: dateRangeStaticRanges.endOfToday,
    }),
  },
  yesterday: {
    label: 'Yesterday',
    range: () => ({
      startDate: dateRangeStaticRanges.startOfYesterday,
      endDate: dateRangeStaticRanges.endOfYesterday,
    }),
  },

  thisWeek: {
    label: 'This Week',
    range: () => ({
      startDate: dateRangeStaticRanges.startOfWeek,
      endDate: dateRangeStaticRanges.endOfWeek,
    }),
  },
  lastWeek: {
    label: 'Last Week',
    range: () => ({
      startDate: dateRangeStaticRanges.startOfLastWeek,
      endDate: dateRangeStaticRanges.endOfLastWeek,
    }),
  },
  thisMonth: {
    label: 'This Month',
    range: () => ({
      startDate: dateRangeStaticRanges.startOfMonth,
      endDate: dateRangeStaticRanges.endOfMonth,
    }),
  },
  lastMonth: {
    label: 'Last Month',
    range: () => ({
      startDate: dateRangeStaticRanges.startOfLastMonth,
      endDate: dateRangeStaticRanges.endOfLastMonth,
    }),
  },
};
